import "./officerinfo.scss";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  TextField,
  InputAdornment,
  Avatar,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  Grid,
} from "@mui/material";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//Srcs
import { SelectOrganization, SelectDepartment, SelectOffice } from "../Function/DynamicSelect";
import CreateOfficer from "../Component/Officer/CreateOfficer/CreateOfficer";
import Profile from "../Component/Menu/Profile";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import OfficerAction from "../Component/Officer/OfficerAction/OfficerAction";
import { GET_OFFICER_WITH_PAGINATION } from "../Schema/officer";
import LoadingPage from "../Component/Include/LoadingPage";


export default function OfficerInfo() {

  const [loading, setLoading] = useState(true)
  const [organizationVal, setOrganizationVal] = useState({ id: "", title: "" });
  const [departmentVal, setDepartmentVal] = useState({ id: "", title: "" })
  const [officeVal, setOfficeVal] = useState({ id: "", title: "" });
  const [positionsVal, setPositionsVal] = useState({});

  let navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();
  let officeId = searchParams.get("office");

  // console.log(organizationId, departmentId);

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {    
    console.log(window.innerWidth)
    if(window.innerWidth < 1536) {
      setWidth(window.innerWidth - 100); 
    } else {
      setWidth(window.innerWidth - 320); 
    }    
  };
  React.useEffect(() => {    
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  //handle open and close
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // get Office
  const [dataView, setDataView] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_OFFICER_WITH_PAGINATION, {
    variables: {
      page: keyword !== "" ? 1 : page,
      limit: limit,
      keyword: keyword,
      organization: organizationVal?.id === "" ? null : organizationVal?.id,
      department: departmentVal?.id === "" ? null : departmentVal?.id,
      office: officeVal?.id === "" ? null : officeVal?.id,
    },
    onCompleted: ({ getOfficersPagination }) => {
      // console.log("getOfficersPagination::", getOfficersPagination)
      setDataView(getOfficersPagination?.data);
      setLoading(false)
      // resize table
      if(window.innerWidth < 1536) {
        setWidth(window.innerWidth - 100); 
      } else {
        setWidth(window.innerWidth - 320); 
      }  
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
    // setLoading(true);
  }, [page, limit, keyword ]);

  const handleViewOfficer = (id) => {
    navigate(`/officer-info/view-officer?officerId=${id}`)
  }

  // ============== get value local storage =================
  const [organizSelected,setOrganizSelected] = useState(JSON.parse(window.localStorage.getItem("organizSelected")));
  const [departSelected,setDepartSelected] = useState(JSON.parse(window.localStorage.getItem("departSelected")));
  const [offSelected,setOffSelected] = useState(JSON.parse(window.localStorage.getItem("offSelected")));

  useEffect(() => {
    setOrganizationVal(organizSelected)
    setDepartmentVal(departSelected)
    setOfficeVal(offSelected)
  }, [organizSelected, departSelected, offSelected , open])


  return (
    <div className="officer-info-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">
            <Link to="/setting" className="link-homepage">
              ការកំណត់
            </Link>{" "}
            / មន្រ្តី
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Grid container spacing={2}>

        <Grid item xs={6} lg={3} xl={2.4}>
          <Box className="search-container">
            <TextField
              onChange={(e) => setKeyword(e.target.value)}
              className="text-field"
              fullWidth
              id="input-with-sx"
              placeholder="ស្វែងរក"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={6} lg={3} xl={2.4}>
          <Box className="search-container">
            <SelectOrganization
              selectedVal={organizationVal}
              setSelectedVal={setOrganizationVal}
              setDepartmentVal={setDepartmentVal}
              setOfficeVal={setOfficeVal}
              setPositionsVal={setPositionsVal}
              className="text-field"
            />
          </Box>
        </Grid>

        <Grid item xs={6} lg={3} xl={2.4}>
          <Box className="search-container">
            <SelectDepartment
              selectedVal={departmentVal}
              setSelectedVal={setDepartmentVal}
              organizationId={organizationVal?.id}
              className="text-field"
            />
          </Box>
        </Grid>

        <Grid item xs={6} lg={3} xl={2.4}>
          <Box className="search-container">
            <SelectOffice
              selectedVal={officeVal}
              setSelectedVal={setOfficeVal}
              organizationId={organizationVal?.id}
              departmentId={departmentVal?.id}
              className="text-field"
            />
          </Box>
        </Grid>

        {
          organizSelected !== null && departSelected !== null && offSelected !== null ?
            <Grid item xs={12} xl={2.4}>
              <Stack direction="row" justifyContent="right">
                <Button
                  onClick={handleOpen}
                  startIcon={<BiLayerPlus />}
                  className="btn-add"
                >
                  បន្ថែមថ្មី
                </Button>

                <Drawer
                  sx={{
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: 700,
                    },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={open}
                >
                  <CreateOfficer
                    officeId={officeId}
                    setRefetch={refetch}
                    handleClose={handleClose}
                    open={open}
                    organizationValSelected={organizationVal}
                    departmentValSelected={departmentVal}
                    officeValSelected={officeVal}
                  />
                </Drawer>
              </Stack>
            </Grid>
            : null
        }



      </Grid>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title">ល.រ</TableCell>
                <TableCell className="header-title">អត្តលេខមន្រ្តីរាជការ</TableCell>
                <TableCell className="header-title">គោត្តនាម-នាម</TableCell>
                <TableCell className="header-title">​ភេទ</TableCell>
                <TableCell className="header-title">ថ្ងៃខែឆ្នាំកំណើត</TableCell>
                <TableCell className="header-title">ថ្ងៃខែឆ្នាំចូលបម្រើការងារ</TableCell>
                <TableCell className="header-title">ថ្ងៃខែឆ្នាំតាំងស៊ប់</TableCell>
                <TableCell className="header-title">ប្រភេទក្របខ័ណ្ឌ</TableCell>
                <TableCell className="header-title">មុខ​តំណែង</TableCell>
                <TableCell className="header-title">ថ្ងៃខែឆ្នាំចូលកាន់មុខតំណែង</TableCell>
                <TableCell className="header-title">កម្រិតវប្បធម៌</TableCell>
                <TableCell className="header-title">លេខទូរស័ព្ទ</TableCell>
                <TableCell className="header-title">ផ្សេងៗ</TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>

            {
              loading ?
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title" rowSpan={4} colSpan={13}>
                      <LoadingPage />
                    </TableCell>
                  </TableRow>
                </TableHead>
                :

                <TableBody className="body">
                  {dataView?.map((row, index) => (
                    <TableRow className={(index + 1) % 2 === 1 ? "body-row" : "body-row-active"} key={index}>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {page === 1 ? index + 1 : null}{page !== 1 ? index + 1 + (page - 1) * (limit) : null}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.officerId}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            src={`${process.env.REACT_APP_IMAGE_SERVER}fileName:${row?.imageSrc + process.env.REACT_APP_IMAGE_URL
                              }`}
                          />
                          <Stack direction="column" justifyContent="center">
                            {row?.firstName + " " + row?.lastName}
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>{row?.gender}</TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {moment(row?.dateOfBirth).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.joiningDate === null ? "" : moment(row?.joiningDate).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.fullPositionDate === null ? "" : moment(row?.fullPositionDate).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.officerFramework?.contractName}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.positions?.name}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.joinPositionsDate === null ? "" : moment(row?.joinPositionsDate).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        <div className="text-degree-limit">
                          {row?.educationLevel[0]?.degree}{" "}{row?.educationLevel[0]?.major}
                        </div>
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>
                        {row?.phoneNumber}
                      </TableCell>
                      <TableCell className="body-title" onClick={() => handleViewOfficer(row?._id)}>{row?.remark}</TableCell>
                      <TableCell className="body-title">
                        <OfficerAction
                          editData={row}
                          setRefetch={refetch}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            }
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2, padding: "0px 15px 20px 0px" }}>
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={data?.getOfficersPagination?.paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>

          <Box width="120px" marginLeft={2} marginRight={2}>
            <FormControl fullWidth size="small">
              <Select 
                value={limit} 
                onChange={(e) => { 
                  setLimit(e.target.value); 
                  setPage(1); 
                }}
              >
                <MenuItem value={10}>10 / page</MenuItem>
                <MenuItem value={20}>20 / page</MenuItem>
                <MenuItem value={50}>50 / page</MenuItem>
                <MenuItem value={100}>100 / page</MenuItem>
                {/* <MenuItem value={200}>200 / page</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}
