import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuNavbarMobile from "./MenuNavbarMobile";

export default function IconMenuResponsive() {

  const drawerWidth = 250;

  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // Resize width
  const [width, setWidth] = React.useState(window.innerWidth);

  const updateDimensions = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      <React.Fragment key={"left"}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer("left", true)}
          edge="start"
          sx={{ display: width > 1400 ? "none" : "block" }}
        >
          <Stack direction="column" justifyContent="center">
            <MenuIcon sx={{ color: "#0B82C4" }} />
          </Stack>
        </IconButton>

        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          <Box
            sx={{ width: drawerWidth }}
            role="presentation"            
            onKeyDown={toggleDrawer("left", false)}
          >
            <MenuNavbarMobile  toggleDrawer={toggleDrawer}/>
          </Box>
        </Drawer>
      </React.Fragment>

    </>
  );
}
