import React, { useState, useContext } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Button,
  MobileStepper
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
//Ocpms
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
//Srcs
import "./evaluationofficer.scss";
import { AuthContext } from "../../../Context/AuthContext";
import IconMenuResponsive from "../../Menu/IconMenuResponsive";
import Profile from "../../Menu/Profile";
import { GET_OFFICER_BY_ID } from '../../../Schema/officer'
import RespectAndResponsibilities from "./RespectAndResponsibilities";
import Morality from "./Morality";
import FamilyStatus from "./FamilyStatus";
import respectandresponsibilities from "../JsonData/respectandresponsibilities.json"
import morality from "../JsonData/morality.json"
import familystatus from "../JsonData/familystatus.json"
import SubmitForm from "../SubmitForm/SubmitForm";

export default function EvaluationOfficer() {

  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  const [officerData, setOfficerData] = useState([])

  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let officerId = params.get("officerId");

  //open submit form
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectRespectData, setSelectRespectData] = useState(respectandresponsibilities);
  const [selectMoralityData, setSelectMoralityData] = useState(morality);
  const [selectFamilyStatusData, setSelectFamilyStatusData] = useState(familystatus);

  const { data, refetch } = useQuery(GET_OFFICER_BY_ID, {
    variables: {
      officerId: officerId,
    },
    onCompleted: ({ getOfficerById }) => {
      // console.log("getOfficerById::", getOfficerById)
      setOfficerData(getOfficerById);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const steps = [
    {
      pageTitle: 'ការគោរព និងតួនាទីភារកិច្ច / មាន ២០ ចំណុច',
      pageData: <RespectAndResponsibilities selectRespectData={selectRespectData} setSelectRespectData={setSelectRespectData} />,
    },
    {
      pageTitle: 'សីសធម៌ / មាន ១០ ចំណុច',
      pageData: <Morality selectMoralityData={selectMoralityData} setSelectMoralityData={setSelectMoralityData} />,
    },
    {
      pageTitle: 'ស្ថានភាពគ្រួសារ / មាន ៥ ចំណុច',
      pageData: <FamilyStatus selectFamilyStatusData={selectFamilyStatusData} setSelectFamilyStatusData={setSelectFamilyStatusData} />,
    },
  ];

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // console.log("selectRespectData::", selectRespectData, selectMoralityData, selectFamilyStatusData)

  // let lastItem = selectRespectData.pop();
  // let arr1 = []
  //  selectRespectData?.filter(d => d?.value === 0)?.map(d => {
  //  })


  return (
    <Box className="evaluation-officer">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">
            <Link to="/evaluation" className="link-homepage">
              ការវាយតម្លៃ
            </Link>{" "}
            / {officerData?.firstName + " " + officerData?.lastName}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Box className="container">
        <Grid container rowSpacing={1.5} columnSpacing={4}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ width: "100%", flexGrow: 1 }}>
              <Stack direction="row" className="stack-title">
                <Typography className="body-text">{steps[activeStep].pageTitle}</Typography>
              </Stack>
              <Box className="body-box-container">
                {steps[activeStep].pageData}
              </Box>
              <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Stack direction="row" spacing={2}>
                    <Button
                      className={activeStep === maxSteps - 1 ? "btn-next" : "btn-next-active"}
                      onClick={handleNext}
                    // sx={{ display: activeStep === maxSteps - 1 ? "none" : "flex" }}
                    >
                      បន្ទាប់
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                    {
                      loading ? <Button className="btn-submit" sx={{ display: activeStep === maxSteps - 1 ? "flex" : "none" }}>
                        ...កំពុងដំណើរការ
                      </Button> : <Button className="btn-submit" onClick={handleOpen} sx={{ display: activeStep === maxSteps - 1 ? "flex" : "none" }}>
                        ដាក់បញ្ចូន
                      </Button>
                    }
                  </Stack>
                }
                backButton={
                  <Button
                    className={activeStep === 0 ? "btn-next" : "btn-next-active"}
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    ថយក្រោយ
                  </Button>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <SubmitForm
        open={open}
        handleClose={handleClose}
        selectRespectData={selectRespectData}
        selectMoralityData={selectMoralityData}
        selectFamilyStatusData={selectFamilyStatusData}
      />
    </Box>
  );
}
