import React, { useState, useContext } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Avatar,
  Divider
} from "@mui/material";
import "./viewofficer.scss";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
//Srcs
import OffceFramword from "../../../Assets/chief-executive-officer.png"
import IconMenuResponsive from "../../Menu/IconMenuResponsive";
import Profile from "../../Menu/Profile";
import { AuthContext } from "../../../Context/AuthContext"
import { GET_OFFICER_BY_ID } from '../../../Schema/officer'

export default function CreateOfficer() {
  const [officerData, setOfficerData] = useState([])

  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let officerId = params.get("officerId");

  const { data, refetch } = useQuery(GET_OFFICER_BY_ID, {
    variables: {
      officerId: officerId,
    },
    onCompleted: ({ getOfficerById }) => {
      // console.log("getOfficerById::", getOfficerById)
      setOfficerData(getOfficerById);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // console.log("officerData::", officerData)

  return (
    <Box className="view-officer">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">
            <Link to="/setting" className="link-homepage">
              ការកំណត់
            </Link>{" "}
            /{" "}
            <Link to="/officer-info" className="link-homepage">
              មន្រ្តី
            </Link>{" "}
            / {officerData?.firstName + " " + officerData?.lastName}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Box className="container">
        <Grid container rowSpacing={1.5} columnSpacing={4}>
          <Grid item xs={12} md={6} xl={4}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="center" sx={{ padding: "12px 0px" }}>
                <img
                  className="avater-image"
                  src={officerData?.imageSrc === "" ? OffceFramword : `${process.env.REACT_APP_IMAGE_SERVER}fileName:${officerData?.imageSrc + process.env.REACT_APP_IMAGE_URL}`}
                />
              </Stack>

              <Stack direction="row" className="stack-title">
                <Typography className="body-text">គោត្តនាម នាម</Typography>
                <Typography className="body-text">ភេទ</Typography>
                <Typography className="body-text">អាយុ</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{officerData?.firstName + " " + officerData?.lastName}</Typography>
                <Typography className="body-des">{officerData?.gender}</Typography>
                <Typography className="body-des">
                  {moment().diff(
                    moment(
                      officerData?.dateOfBirth
                    ),
                    "years"
                  ) + " ឆ្នាំ"}
                </Typography>
              </Stack>

              <Stack direction="row" className="stack-title">
                <Typography className="body-text">ថ្ងៃខែឆ្នាំកំណើត</Typography>
                <Typography className="body-text">អត្តលេខមន្រ្តីរាជការ</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{moment(officerData?.dateOfBirth).format("DD-MMM-YYYY")}</Typography>
                <Typography className="body-des">{officerData?.officerId}</Typography>
              </Stack>
            </Stack>
          </Grid>

          {/* ====================================================== Second grid ===================================================== */}
          <Grid item xs={12} md={6} xl={4}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" className="stack-title">
                <Typography className="body-text">ប្រភេទក្របខ័ណ្ឌ</Typography>
                <Typography className="body-text">ប្រភេទមន្រ្តី</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{officerData?.officerFramework?.contractName}</Typography>
                <Typography className="body-des">{officerData?.officerType}</Typography>
              </Stack>

              <Stack direction="row" className="stack-title">
                <Typography className="body-text">ថ្ងៃខែឆ្នាំចូលបម្រើការងារ</Typography>
                <Typography className="body-text">ថ្ងៃខែឆ្នាំចូលកាន់មុខតំណែង</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{moment(officerData?.joiningDate).format("DD-MMM-YYYY")}</Typography>
                <Typography className="body-des">{moment(officerData?.joinPositionsDate).format("DD-MMM-YYYY")}</Typography>
              </Stack>

              <Stack direction="row" className="stack-title">
                <Typography className="body-text"> អង្គភាព</Typography>
                <Typography className="body-text"> នាយកដ្ឋាន</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{officerData?.organization?.name}</Typography>
                <Typography className="body-des">{officerData?.department?.name}</Typography>
              </Stack>

              <Stack direction="row" className="stack-title">
                <Typography className="body-text">ការិយាល័យ</Typography>
                <Typography className="body-text">មុខតំណែង</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{officerData?.officerType}</Typography>
                <Typography className="body-des">{officerData?.positions?.name}</Typography>
              </Stack>

            </Stack>
          </Grid>

          {/* ====================================================== Third grid ===================================================== */}
          <Grid item xs={12} md={6} xl={4}>
            <Stack direction="column" spacing={2} sx={{ marginTop: { xs: "20px", xl: "0px" } }}>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>កម្រិតវប្បធម៌ទូទៅ</Typography>
                <Typography className="body-des"></Typography>
              </Stack>
              <Stack direction="row" className="stack-title">
                <Typography className="body-text">ឯកទេស</Typography>
                <Typography className="body-text">កម្រិតបរិញ្ញាបត្រ</Typography>
              </Stack>

              {
                officerData?.educationLevel?.map((data, index) => {
                  return (
                    <Stack direction="row">
                      <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{data.major}</Typography>
                      <Typography className="body-des">{data.degree}</Typography>
                    </Stack>
                  )
                })
              }

              <Stack direction="row" className="stack-title">
                <Typography className="body-text">លេខទូរស័ព្ទ</Typography>
                <Typography className="body-text">ផ្សេងៗ</Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="body-des" sx={{ paddingLeft: "20px" }}>{officerData?.phoneNumber}</Typography>
                <Typography className="body-des">{officerData?.remark}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
