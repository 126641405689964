import React, { useState, useContext , useEffect } from "react";
import "./createuser.scss";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";

//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import { UPDATE_OFFICER_FRAMEWORK } from "../../../Schema/officerframework";

export default function UpdateOfficeFramework({
  setRefetch,
  handleClose,
  editData,
  open
}) {

  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);


  // Create
  const [updateContractsOfficer] = useMutation(UPDATE_OFFICER_FRAMEWORK, {
    onCompleted: ({ updateContractsOfficer }) => {      
      if (updateContractsOfficer?.status === true) {
        setAlert(true, "success", updateContractsOfficer?.message)
        handleClose();
        resetForm();
        setLoading(false);
        setRefetch()
      } else {
        setAlert(true, "error", updateContractsOfficer?.message)
        setLoading(false);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  // Formik
  const addOfficeFramework = Yup.object().shape({
    contractName: Yup.string().required("ទាមទារ!"),   
    baseSalary: Yup.string(),    
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      contractName: "",
      baseSalary: "",
      remark: "",      
    },

    validationSchema: addOfficeFramework,
    onSubmit: (values) => {
      setLoading(true);

      const newOfficer = {
        contractName: values?.contractName,
        baseSalary: parseFloat(values?.baseSalary),
        remark: values?.remark,       
      };

      updateContractsOfficer({
        variables: {
          updateContractsOfficerId: editData?._id,
          input: newOfficer,
        },
      });

    },
  });

  const { errors, touched, handleSubmit, getFieldProps, resetForm , setFieldValue } = formik;

  useEffect( () => {
    if(editData) {
      // console.log(editData)
      setFieldValue("contractName", editData?.contractName)
      setFieldValue("baseSalary", editData?.baseSalary )
      setFieldValue("remark", editData?.remark)
    }
  },[editData,open])

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="udapte-framework">

            <Stack direction="row" spacing={2}>
                <Typography variant="h6" className="title">
                  បង្កើតក្របខ័ណ្ឌមន្ត្រី
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleClose}>
                  <ClearIcon sx={{ color: "red" }} />
                </IconButton>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">
                  ឈ្មោះក្របខ័ណ្ឌ
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ឈ្មោះក្របខ័ណ្ឌ"
                  {...getFieldProps("contractName")}
                  error={Boolean(touched.contractName && errors.contractName)}
                  helperText={touched.contractName && errors.contractName}
                />
              </Grid>
             
              <Grid item xs={12}>
                <Typography className="Sub-title">សន្ទស្សន៍បៀវត្ស</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="សន្ទស្សន៍បៀវត្ស"
                  {...getFieldProps("baseSalary")}
                  error={Boolean(touched.baseSalary && errors.baseSalary)}
                  helperText={touched.baseSalary && errors.baseSalary}
                />
              </Grid>
              

              <Grid item xs={12}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
              

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                  >
                    បង្កើតថ្មី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
