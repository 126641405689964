import {
  Avatar,
  Stack,
  Typography,
} from "@mui/material";
import "./profile.scss"
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { GET_USER_LOGIN } from "../../Schema/user";
import { useQuery } from "@apollo/client";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export default function Profile() {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const [userData, setUserData] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      // console.log(getUserLogin)
      setUserData(getUserLogin);
      setLoading(true);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoading(false);
    },
  });

  // console.log("userData::", userData)

  return (
    <Stack direction="row" spacing={2} className="top-bar">
      <Stack direction="column" justifyContent="center">
        <Avatar 
          src={`${process.env.REACT_APP_IMAGE_SERVER}fileName:${userData?.photoSrc + process.env.REACT_APP_IMAGE_URL}`}
          alt={`${userData?.username}`} 
        />
      </Stack>
      <Stack direction="column" justifyContent="center">
        <Typography className="user-name">{userData?.username}</Typography>
        <Typography className="user-role">{userData?.position}</Typography>
      </Stack>
    </Stack>
  );
}
