import { gql } from "@apollo/client";

export const OFFICER_LIST_REPORT = gql`
query OfficerListReport($organizationId: ID) {
  officerListReport(organizationId: $organizationId) {
    officeName
    remark
    officerList {
      _id
      officerId
      firstName
      lastName
      gender
      dateOfBirth
      phoneNumber
      joiningDate
      fullPositionDate
      contractName
      currentPositions
      joinPositionsDate
      educationLevel
      major
    }
  }
}
`;
