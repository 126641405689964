import React, { useEffect, useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Typography, Box, Grid, TextField, IconButton, Autocomplete, InputAdornment, FormControl, Select, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { GET_BONUS_TYPE } from "../../Schema/Setting";
import { useQuery } from "@apollo/client";

export default function ListEducation(props) {

    // get Bonus Type =====================================================================
    const [optionBonus, setOptionBonus] = useState([]);
    
    const items = props.items;
    const listItems = items.map((item) => {
        return (
            <Box key={item.key} sx={{ mb: 2 }}>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className="Sub-title">សញ្ញាបត្រ</Typography>
                        {/* <TextField
                            className="text-field"
                            size="small"
                            fullWidth
                            placeholder="សញ្ញាបត្រ"
                            value={item?.degree}
                            onChange={(e) => props?.setUpdateDegree(e.target.value, item?.key)}
                        /> */}
                        <FormControl fullWidth size="small">                           
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"                                
                                value={item?.degree}                                
                                onChange={(e) => props?.setUpdateDegree(e.target.value, item?.key)}
                            >
                                <MenuItem value="បណ្ឌិត">បណ្ឌិត</MenuItem>
                                <MenuItem value="បរិញ្ញាបត្រជាន់ខ្ពស់">បរិញ្ញាបត្រជាន់ខ្ពស់</MenuItem>
                                <MenuItem value="បរិញ្ញាបត្រ">បរិញ្ញាបត្រ</MenuItem>
                                <MenuItem value="បរិញ្ញាបត្ររង">បរិញ្ញាបត្ររង</MenuItem>
                                <MenuItem value="មធ្យមសិក្សាទុតិយភូមិ">មធ្យមសិក្សាទុតិយភូមិ</MenuItem>
                                <MenuItem value="មធ្យមសិក្សាបឋមភូមិ">មធ្យមសិក្សាបឋមភូមិ</MenuItem>
                                <MenuItem value="បឋមសិក្សា">បឋមសិក្សា</MenuItem>
                                <MenuItem value="បណ្តុះបណ្តាលវិជ្ជាជីវៈ">បណ្តុះបណ្តាលវិជ្ជាជីវៈ</MenuItem>                           
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={5}>
                        <Typography className="Sub-title">ឯកទេស</Typography>
                        <TextField
                            className="text-field"
                            size="small"
                            fullWidth
                            placeholder="ឯកទេស"
                            value={item?.major}
                            onChange={(e) => props?.setUpdateMajor(e.target.value, item?.key)}
                        />
                    </Grid>

                    {/* <Grid item xs={3.5}>
                        <Typography className="Sub-title">ចាប់ផ្ដើម</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={item?.joinYear}
                                onChange={(e) => props?.setUpdateStartDate(e, item?.key)}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        className="text-field"
                                        {...params}
                                        placeholder="This Month"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3.5}>
                        <Typography className="Sub-title">បញ្ចប់</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={item?.endDate}
                                onChange={(e) => props?.setUpdateEndDate(e, item?.key)}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        className="text-field"
                                        {...params}
                                        placeholder="This Month"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid> */}
                    <Grid item xs={1} textAlign="right">
                        <IconButton
                            sx={{ cursor: "pointer", marginTop: "20px" }}
                            onClick={() => {
                                props.deleteEducation(item.key);
                            }}
                        >
                            <DeleteRoundedIcon sx={{ color: "red" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        );
    });
    return listItems;
}
