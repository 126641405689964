import { gql } from "@apollo/client";

export const CREATE_OFFICE = gql`
mutation CreateOffice($name: String!, $remark: String!, $organization: ID!, $department: ID!, $officeRanking: Int!) {
  createOffice(name: $name, remark: $remark, organization: $organization, department: $department, officeRanking: $officeRanking) {
    message
    status
  }
}
`;

export const GET_LAST_OFFICE_RANKING = gql`
query Query($organizationId: ID!) {
  getLastOfficeRanking(organizationId: $organizationId)
}
`

export const GET_OFFICE_BY_DEPARTMENT_PAGINATION = gql`
  query GetOfficePagination(
    $page: Int!
    $limit: Int!
    $keyword: String!
    $departmentId: ID!
  ) {
    getOfficePagination(
      page: $page
      limit: $limit
      keyword: $keyword
      departmentId: $departmentId
    ) {
      data {
        _id
        name
        officeRanking
        organization {
          _id
          name
          remark
          createdAt
        }
        department {
          _id
          name
          organization {
            _id
            name
            remark
            createdAt
          }
          remark
          createdAt
        }
        remark
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`;

export const UPDATE_OFFICE = gql`
mutation UpdateOffice($id: String!, $name: String!, $remark: String!, $organization: ID!, $departemnt: ID!, $officeRanking: Int!) {
  updateOffice(_id: $id, name: $name, remark: $remark, organization: $organization, departemnt: $departemnt, officeRanking: $officeRanking) {
    message
    status
  }
}
`;

export const DELETE_OFFICE = gql`
  mutation DeleteOffice($id: String!) {
    deleteOffice(_id: $id) {
      message
      status
    }
  }
`;

export const GET_OFFICE_BY_ORGANIZATION = gql`
query GetOfficesbyDepOrOrg($organizationId: ID!, $departmentId: ID) {
  getOfficesbyDepOrOrg(organizationId: $organizationId, departmentId: $departmentId) {
    _id
    name
    officeRanking
  }
}
`;
