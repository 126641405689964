import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Link, Route, useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import CircularProgress from '@mui/material/CircularProgress';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import app from "../../src/firebase";
import "./login.scss";
import logiImage from "../Assets/logoLogin.svg";
import { AuthContext } from "../Context/AuthContext";

export default function Login() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext)

  const [iconReload, setIconReload] = useState(false);

  const auth = getAuth(app);
  // console.log("auth::", auth)

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("អ៊ីម៉ែលមិនត្រឹមត្រូវទេ!").required("ទាមទារ!"),
    password: Yup.string()
      .required("ទាមទារ!")
      .min(6, "ពាក្យសម្ងាត់ត្រូវតែលើសពី ៦ ខ្ទង់!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      // console.log("values::", values);
      setIconReload(true)

      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          const user = userCredential.user;
          // console.log("userCredential::", user);

          setTimeout(() => {
            setAlert(true, "success", "ចូលប្រព័ន្ធបានជោគជ័យ!");
            setIconReload(false);
            navigate("/");
          }, 1200);
        })
        .catch((error) => {
          // console.log("error::", error);
          setIconReload(false);
          setAlert(true, "error", "ខុសអ៊ីម៉ែល/ពាក្យសម្ងាត់!");
        });

      //get User Date after login Success
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // const uid = user.uid;
          // console.log(user, "user");
        } else {
          // User is signed out
        }
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="login-page">
            <Box className="background-image" />
            <Box className="container">
              <Box className="box-logo">
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  variant="square"
                  alt="logo"
                  src={logiImage}
                />
              </Box>
              <Box className="box-text" sx={{ mt: 1 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  spacing={1}
                  className="title-login"
                >
                  <Typography className="title">
                    Welcome to Officer System
                  </Typography>
                  <Typography
                    className="sub-title"
                    variant="body2"
                    align="center"
                  >
                    Sign In to continue
                  </Typography>
                </Stack>
              </Box>

              <Box className="box-login" sx={{ mt: 3 }}>
                <Stack direction="column" justifyContent="center" spacing={2}>
                  <TextField
                    className="text-field"
                    size="small"
                    placeholder="example@company.com"
                    fullWidth
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon className="icon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="text-field"
                    type={show ? "text" : "password"}
                    size="small"
                    placeholder="password"
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HttpsOutlinedIcon className="icon" />
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment position="start" onClick={handleClick}>
                          {show ? (
                            <VisibilityIcon sx={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    to="/forgotpassword"
                    style={{ textDecorationColor: "#fff" }}
                  >
                    <Typography
                      variant="subtitle2"
                      align="right"
                      color="#fff"
                      fontWeight="bold"
                    >
                      ភេ្លចពាក្យសម្ងាត់
                    </Typography>
                  </Link>
                  <Button
                    className="btb-sign-in"
                    type="submit"
                    endIcon={iconReload ? <CircularProgress sx={{ color: "#0f81c2" }} size="20px" /> : null}
                  >
                    ចូល
                  </Button>
                </Stack>
              </Box>
            </Box>
            <Typography
              variant="body2"
              align="center"
              color="#fff"
              sx={{ mb: 3, letterSpacing: "2px" }}
            >
              @Copyright 2022, Officer System
            </Typography>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
