import "./organization.scss";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  TextField,
  InputAdornment,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
//icon from material
import Profile from "../Component/Menu/Profile";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
//component
import CreateOrganization from "../Component/Organization/CreateOrganization/CreateOrganization";
import { useQuery } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//draw
import Drawer from "@mui/material/Drawer";
import { GET_ORGANIZATION_PAGINATION } from "../Schema/organization";
import { useNavigate } from "react-router-dom";
import OrganizationAction from "../Component/Organization/OrganizationAction/OrganizationAction";

export default function Organization() {
  const navigate = useNavigate();

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  //handle open and close
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const drawerWidth = 420;

  // get organization
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const [tableData, setTableData] = useState(null);
  const { data, refetch } = useQuery(GET_ORGANIZATION_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
    },
    onCompleted: ({ getOrganizationPagination }) => {
      // console.log(getOrganizationPagination)
      setTableData(getOrganizationPagination?.data);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // console.log("tableData::", tableData)

  useEffect(() => {
    refetch();
  }, [page, limit, keyword]);

  return (
    <div className="organization-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">
            <Link to="/setting" className="link-homepage">
              ការកំណត់
            </Link>{" "}
            / អង្គភាព
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>

        <Profile />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="left">
            <Box className="search-container">
              <TextField
                onChange={(e) => setKeyword(e.target.value)}
                className="text-field"
                fullWidth
                id="input-with-sx"
                placeholder="ស្វែងរក"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={handleOpen}
              startIcon={<BiLayerPlus />}
              className="btn-add"
            >
              បន្ថែមថ្មី
            </Button>

            <Drawer
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                },
              }}
              variant="persistent"
              anchor="right"
              open={open}
            >
              <CreateOrganization
                setRefetch={refetch}
                handleClose={handleClose}
              />
            </Drawer>
          </Stack>
        </Grid>
      </Grid>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" width="100px">
                  ល.រ
                </TableCell>
                <TableCell className="header-title">ឈ្មោះអង្គភាព</TableCell>
                <TableCell className="header-title">ផ្សេងៗ</TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody component={Paper} className="body">
              {tableData?.map((row, index) => (
                <TableRow className="body-row" key={index}>
                  <TableCell
                    className="body-title"
                    onClick={() =>
                      navigate(`/department?organization=${row?._id}`)
                    }
                  >
                    <Typography sx={{ fontFamily: "Century Gothic" }}>
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="body-title"
                    onClick={() =>
                      navigate(`/department?organization=${row?._id}`)
                    }
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar alt="Logo" src={`${process.env.REACT_APP_IMAGE_SERVER}fileName:${row?.imageSrc + process.env.REACT_APP_IMAGE_URL}`} />
                      <Stack direction="column" justifyContent="center">
                        <Typography>{row?.name}</Typography>
                      </Stack>
                    </Stack>

                  </TableCell>
                  <TableCell
                    className="body-title"
                    onClick={() =>
                      navigate(`/ department ? organization = ${row?._id}`)
                    }
                  >
                    {row?.remark}
                  </TableCell>
                  <TableCell className="body-title" align="right">
                    <OrganizationAction
                      editData={row}
                      setRefetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={data?.getOrganizationPagination?.paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>

          <Box width="120px" marginLeft={2} marginRight={2}>
            <FormControl fullWidth size="small">
              <Select value={limit} onChange={(e) => setLimit(e.target.value)}>
                <MenuItem value={10}>10 / page</MenuItem>
                <MenuItem value={20}>20 / page</MenuItem>
                <MenuItem value={50}>50 / page</MenuItem>
                <MenuItem value={100}>100 / page</MenuItem>
                <MenuItem value={200}>200 / page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}
