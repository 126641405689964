import React, { useReducer, useEffect, useState } from 'react';
import { createContext } from 'react';
import { auth } from '../firebase';

// reducer
const firebaseReducer = (state, action) => {
    switch (action.type) {
        case 'LOGGED_IN_USER':
            return { ...state, user: action.payload };
        default:
            return state;
    }
};

// state
const initialState = {
    user: null
};

// create context
const AuthContext = createContext();

// context provider
const AuthProvider = ({ children }) => {

    const [state, dispatch] = useReducer(firebaseReducer, initialState);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                // console.log(user, "user");
                const idTokenResult = await user?.accessToken;
                // console.log(idTokenResult , "token")
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: {
                        email: user?.email,
                        token: idTokenResult,
                    }
                });
            } else {
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: null
                });
            }
        });
        // cleanup
        return () => unsubscribe();
    }, []);

    // Alert Message =================================================
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState("");
    const [messageAlert, setMessageAlert] = useState("");

    const setAlert = (open, alert, message) => {
        setOpen(open);
        setAlertStatus(alert);
        setMessageAlert(message);
    };
    const alert = () => {
        return { open: open, status: alertStatus, message: messageAlert };
    };

    const value = {
        state,
        dispatch,
        alert,
        setAlert,
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export
export { AuthContext, AuthProvider };