import React, { useState } from "react";
import "./blockheader.scss";
import { Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
//Srcs
import FirstShareImg from "../../../Assets/icon_dashboard/Group_40.png";
import ThirdShareImg from "../../../Assets/icon_dashboard/Group_25.png";
import { GET_CARD_DATA } from "../../../Schema/dashboard"

export default function BlockHeader() {

  const [cardData, setCardData] = useState({})

  const { data } = useQuery(GET_CARD_DATA, {
    onCompleted: ({ getCardData }) => {
      setCardData(getCardData)
      // console.log("getCardData::", getCardData)
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  return (
    <Grid container spacing={3} className="header-container">

      <Grid item xs={12} sm={6} xl={3}>
        <Stack direction="row" spacing={1} className="stack-container-second">
          <Stack direction="column" justifyContent="center">
            <img
              src={FirstShareImg}
              alt="SecoundShareImg"
              className="front-icon"
            />
          </Stack>
          <Stack direction="column" justifyContent="space-between">
            <Typography className="share-number">{cardData?.nationalOfficer}</Typography>
            <Typography className="share-des">មន្រី្តថ្នាក់ជាតិ</Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} xl={3}>
        <Stack direction="row" spacing={1} className="stack-container-third">
          <Stack direction="column" justifyContent="center">
            <img
              src={FirstShareImg}
              alt="ThirdShareImg"
              className="front-icon"
            />
          </Stack>
          <Stack direction="column" justifyContent="space-between">
            <Typography className="share-number">{cardData?.provincialOfficials}</Typography>
            <Typography className="share-des">មន្រី្តថ្នាក់ខេត្ត</Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} xl={3}>
        <Stack direction="row" spacing={1} className="stack-container-first">
          <Stack direction="column" justifyContent="center">
            <img
              src={FirstShareImg}
              alt="FirstShareImg"
              className="front-icon"
            />
          </Stack>
          <Stack direction="column" justifyContent="space-between">
            <Typography className="share-number">{cardData?.districtOfficer}</Typography>
            <Typography className="share-des">មន្រី្តថ្នាក់ស្រុក</Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} xl={3}>
        <Stack direction="row" spacing={1} className="stack-container-fourth">
          <Stack direction="column" justifyContent="center">
            <img
              src={ThirdShareImg}
              alt="FourthShareImg"
              className="front-icon"
            />
          </Stack>
          <Stack direction="column" justifyContent="space-between">
            <Typography className="share-number">{cardData?.communeOfficer}</Typography>
            <Typography className="share-des">មន្រី្តថ្នាក់ឃុំ</Typography>
          </Stack>
        </Stack>
      </Grid>

    </Grid>
  );
}
