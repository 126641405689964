import React, { useContext } from "react";
import Router from "./routes";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { GlobalDebug } from "./Function/RemoveConsole";
import AlertMessage from "./Component/AlertMessage/AlertMessage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { AuthContext } from "./Context/AuthContext";
import { setContext } from "@apollo/client/link/context";

function App() {
  //Apollo
  const { state } = useContext(AuthContext);
  const { user } = state;

  // React.useEffect(() => {
  //   (process.env.NODE_ENV === "development" ||
  //     process.env.React_APP_END_POINT ===
  //       "https://inventory-endpoint.goglobalmart.com/api") &&
  //     GlobalDebug(false);
  // }, []);

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_END_POINT,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: user ? user?.token : "",
      },
    };
  });

  // console.log("state", state);

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const [prefersDarkMode, setPrefersDarkMode] = React.useState(false);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: prefersDarkMode ? "#121212" : "#f8f8f8",
          },
        },
        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routers>
          <Router
            prefersDarkMode={prefersDarkMode}
            setPrefersDarkMode={setPrefersDarkMode}
          />
        </Routers>
        <CssBaseline />
      </ThemeProvider>
      <AlertMessage />
    </ApolloProvider>
  );
}

export default App;
