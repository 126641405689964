import React, { useRef, useState } from "react";
import {
  Box,
  Stack,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { useReactToPrint } from "react-to-print";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
//Srcs
import emptyReport from "../../src/Assets/empty_report.svg";
import "./report.scss";
import { OFFICER_LIST_REPORT } from "../Schema/report"
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import Profile from "../Component/Menu/Profile";
import { SelectOrganization } from "../Function/DynamicSelect";
import DailyOfficerPass from "../Component/Report/DailyOfficerPass";
import DailyOfficer from "../Component/Report/DailyOfficer";

export default function Report() {

  const [organizationVal, setOrganizationVal] = useState({ id: "", title: "" , logo: ""});
  const [typeReport, setTypeReport] = useState("dailyOfficerPass");
  const [reportData, setReportData] = useState()

  const handleChangeReport = (e) => {
    setTypeReport(e)
  }

  const { data, refetch } = useQuery(OFFICER_LIST_REPORT, {
    variables: {
      organizationId: organizationVal?.id,
    },
    onCompleted: ({ officerListReport }) => {
      console.log("officerListReport::", officerListReport)
      setReportData(officerListReport);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  //for print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="report-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="color"> របាយការណ៍ </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6} lg={3}>
          {/* <Box className="search-container"> */}
          <FormControl fullWidth className="text-field" size="small">
            <Select
              value={typeReport}
              onChange={(e) => handleChangeReport(e.target.value)}
            >
              <MenuItem value="dailyOfficerPass">បញ្ជីបច្ចុប្បន្នភាពមន្រ្តីរាជការស៊ីវិល</MenuItem>
              <MenuItem value="dailyOfficer">បញ្ជីបច្ចុប្បន្នភាពមន្រ្តីរាជការ</MenuItem>
            </Select>
          </FormControl>
          {/* </Box> */}
        </Grid>

        <Grid item xs={6} lg={3}>
          {/* <Box className="search-container"> */}
          <SelectOrganization
            selectedVal={organizationVal}
            setSelectedVal={setOrganizationVal}
            className="text-field"
          />
          {/* </Box> */}
        </Grid>
        <Grid item xs={6} lg={3}></Grid>

        <Grid item xs={6} lg={3}>
          <Stack direction="row" justifyContent="right">
            <Button className="btn-print" onClick={handalePrint} startIcon={<LocalPrintshopOutlinedIcon />}>
              Print
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {
        organizationVal?.title === "" || organizationVal?.title === undefined ? 
          <Box className="report-container">
              <Stack flexDirection="row" justifyContent="center">
                  <Stack flexDirection="column" justifyContent="center" height="50vh">
                      <img src={emptyReport} width={100}/>
                      {/* <Typography textAlign="center" fontWeight="bold" mt={2}>
                        គ្មានមានទិន្នន័យ
                      </Typography> */}
                  </Stack>          
              </Stack>
          </Box>
        : 
          <Box className="report-container" ref={componentRef}>
            {
              typeReport === "dailyOfficerPass" ? 
                <DailyOfficerPass reportData={reportData} organizationName={organizationVal} /> 
              : <DailyOfficer reportData={reportData} organizationName={organizationVal} />
            }
          </Box>
      }

    </div>
  );
}
