import React, { useContext, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  Avatar,
  Divider,
  TableContainer, Table, TableHead, TableBody, TableCell, TableRow,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import "./submitform.scss";
import { AuthContext } from "../../../Context/AuthContext";

export default function SubmitForm({
  open,
  handleClose,
  selectRespectData,
  selectMoralityData,
  selectFamilyStatusData
}) {

  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // const [updatePositions] = useMutation(UPDATE_POSITIONS, {
  //   onCompleted: ({ updatePositions }) => {
  //     // console.log(updatePositions);
  //     if (updatePositions?.success === true) {
  //       setAlert(true, "success", updatePositions?.message);
  //       handleClose();
  //     } else {
  //       handleClose();
  //       // setAlert(true, "",   updatePositions?.message);
  //     }
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  //fromik
  const CreatePostions = Yup.object().shape({
    averageRespect: Yup.string().required("require!"),
    averageMorality: Yup.string().required("require!"),
    averageFamilyStatus: Yup.string().required("require!"),
    averageTotal: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      averageRespect: 0,
      averageMorality: 0,
      averageFamilyStatus: 0,
      averageTotal: 0,
    },

    validationSchema: CreatePostions,
    onSubmit: async (values) => {
      setLoading(true);

    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  console.log("selectRespectData::", selectRespectData)

  const countRespectData = selectRespectData?.reduce((a, b) => {
    return a + b.value;
  }, 0);

  const countMoralityData = selectMoralityData?.reduce((a, b) => {
    return a + b.value;
  }, 0);

  const countFamilyStatusData = selectFamilyStatusData?.reduce((a, b) => {
    return a + b.value;
  }, 0);

  useEffect(() => {
    setFieldValue("averageRespect", (countRespectData / 20)?.toFixed(2))
    setFieldValue("averageMorality", (countMoralityData / 10)?.toFixed(2))
    setFieldValue("averageFamilyStatus", (countFamilyStatusData / 5)?.toFixed(2))
    setFieldValue("averageTotal", ((countRespectData / 20 + countMoralityData / 10 + countFamilyStatusData / 5) / 3).toFixed(2))
  }, [countRespectData, countMoralityData, countFamilyStatusData])

  // console.log("countRespectData::", countRespectData)

  return (
    <Dialog open={open} className="submit-form" fullWidth maxWidth="sm">
      <DialogTitle id="responsive-dialog-title">
        <Stack direction="row" justifyContent="center">
          <Stack direction="row" flexDirection="column" justifyContent="center">
            <Typography className="dialog-title">ទម្រង់ដាក់ស្នើរ</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }}></Box>

          <IconButton onClick={handleClose}>
            <DoDisturbOnOutlinedIcon
              className="close-icon"
            />
          </IconButton>
        </Stack>
        <Divider sx={{ mt: 1 }} />
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TableContainer>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell className="header-title" width="5%">ល.រ</TableCell>
                    <TableCell className="header-title" width="45%">ចំណងជើង</TableCell>
                    <TableCell className="header-title" width="10%">ពិន្ទុជាមធ្យម</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className="body">
                  <TableRow className="body-row">
                    <TableCell className="body-title" width="10%">
                      ១
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      ការគោរព និងតួនាទីភារកិច្ច
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        placeholder="ពិន្ទុជាមធ្យម"
                        disabled
                        {...getFieldProps("averageRespect")}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="body-row">
                    <TableCell className="body-title" width="10%">
                      ២
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      សីសធម៌
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        placeholder="ពិន្ទុជាមធ្យម"
                        disabled
                        {...getFieldProps("averageMorality")}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="body-row">
                    <TableCell className="body-title" width="10%">
                      ៣
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      ស្ថានភាពគ្រួសារ
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        placeholder="ពិន្ទុជាមធ្យម"
                        disabled
                        {...getFieldProps("averageFamilyStatus")}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className="body-row">
                    <TableCell className="body-title" width="55%" colSpan={2} align="right">
                      ពិន្ទុជាមធ្យមសរុប :
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        placeholder="ពិន្ទុជាមធ្យមសរុប"
                        disabled
                        {...getFieldProps("averageTotal")}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="action-container">
          {loading ? (
            <Button className="btn-create">កំពុងដំណើរការ...</Button>
          ) : (
            <Button className="btn-create" onClick={handleSubmit}>
              បង្កើត
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
