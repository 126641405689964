import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: createUserInput!) {
    createUser(input: $input) {
      message
      status
    }
  }
`;


export const UPDATE_USER = gql`
  mutation UpdateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      message
      status
    }
  }
`

export const GET_USER_LOGIN = gql`
  query GetUserLogin {
    getUserLogin {
      _id
      username
      email
      photoName
      photoSrc
      position
      createdAt
    }
  }
`;


export const GET_USER_PAGINATION = gql`
  query GetUserPagination($limit: Int!, $page: Int!, $keyword: String!) {
    getUserPagination(limit: $limit, page: $page, keyword: $keyword) {
      data {
        _id
        username
        email
        password
        photoName
        photoSrc
        position
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`


export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(_id: $id) {
      message
      status
    }
  }
`;
