import React, { useState, useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import moment from "moment";
//Srcs
import { getFormattedPhoneNum, getRomanNumber } from "../../Function/DynamicFunction"

export default function DailyOfficerPass({ reportData, organizationName }) {

    //set index item
    let indexNumber = 0;
    const getIndexNumber = (index) => {
        indexNumber = index + 1;
        return indexNumber;
    };

    const getIndexNumberNext = () => {
        indexNumber = indexNumber + 1;
        return indexNumber;
    };

    return (
        <div className="setup-report-container">
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Box className="logo-container">
                        <Stack flexDirection="row" justifyContent={"center"}>
                            { organizationName?.logo ? 
                                <img src={`${process.env.REACT_APP_IMAGE_SERVER}fileName:${organizationName?.logo + process.env.REACT_APP_IMAGE_URL}`} className="logo-company" />
                            :
                                <img src={organizationName?.logo} className="logo-company" />
                            }
                        </Stack>
                        <div className="report-top-title">ក្រសួង{(organizationName?.title)?.split('មន្ទីរ')}</div>
                        <div className="report-top-title">{organizationName?.title}ខេត្តសៀមរាប</div>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="row" justifyContent="center">
                        <Box>
                            <div className="report-top-title">ព្រះរាជាណាចក្រកម្ពុជា</div>
                            <div className="report-top-title">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        </Box>
                    </Stack>
                    <Box className="report-top-title" sx={{ marginTop: "70px" }}>
                        បញ្ជីបច្ចុប្បន្នភាពមន្រ្តីរាជការរបស់{organizationName?.title}
                    </Box>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <div className="table-report-container" style={{ marginTop: "15px" }}>
                <table className="daily-officer-table">
                    <tr>
                        <th>ល.រ</th>
                        <th>អត្តលេខមន្រ្តីរាជការ</th>
                        <th>គោត្តនាម និងនាម</th>
                        <th>ភេទ</th>
                        <th>
                            <div>ថ្ងៃខែ</div>
                            <div>ឆ្នាំកំណើត</div>
                        </th>
                        <th>
                            <div>ថ្ងៃខែឆ្នាំចូល</div>
                            <div>បម្រើការងារ</div>
                        </th>
                        <th>
                            <div>ប្រភេទ</div>
                            <div>ក្របខ័ណ្ឌ</div>
                        </th>
                        <th>មុខតំណែង</th>
                        <th>
                            <div>ថ្ងៃខែចូល</div>
                            <div>កាន់តំណែង</div>
                        </th>
                        <th>កម្រិតវប្បធម៌</th>
                        <th>លេខទូរស័ព្ទ</th>
                        <th>ផ្សេងៗ</th>
                    </tr>

                    {reportData?.map((row, index) =>
                        row?.officerList?.map((item, index1) =>
                            index1 === 0 ? (
                                <>
                                    <tr style={{ backgroundColor: "#E0EEF9" }}>
                                        <td colSpan={12} style={{ textAlign: "left" }}>{row?.officeName}</td>
                                    </tr>
                                    <tr key={index}>
                                        <td>
                                            {index === 0 ? getIndexNumber(index1) : getIndexNumberNext()}
                                        </td>
                                        <td>
                                            {item?.officerId}
                                        </td>
                                        <td style={{ textAlign: "left" }}>{item?.firstName + " " + item?.lastName}</td>
                                        <td>{item?.gender}</td>
                                        <td>{moment(item?.dateOfBirth).format("DD/MM/YYYY")}</td>
                                        <td>{moment(item?.joiningDate).format("DD/MM/YYYY")}</td>
                                        <td>{item?.contractName}</td>
                                        <td>{item?.currentPositions}</td>
                                        <td>{item?.joinPositionsDate === null ? "" :  moment(item?.joinPositionsDate).format("DD/MM/YYYY")}</td>
                                        <td>{item?.educationLevel}</td>
                                        <td>{getFormattedPhoneNum(item?.phoneNumber)}</td>
                                        <td></td>
                                    </tr>
                                </>
                            ) : (
                                <tr key={index1}>
                                    <td>
                                        {index === 0 ? getIndexNumber(index1) : getIndexNumberNext()}
                                    </td>
                                    <td>{item?.officerId}</td>
                                    <td style={{ textAlign: "left" }}>{item?.firstName + " " + item?.lastName}</td>
                                    <td>{item?.gender}</td>
                                    <td>{moment(item?.dateOfBirth).format("DD/MM/YYYY")}</td>
                                    <td>{moment(item?.joiningDate).format("DD/MM/YYYY")}</td>
                                    <td>{item?.contractName}</td>
                                    <td>{item?.currentPositions}</td>
                                    <td>{item?.joinPositionsDate === null ? "" :  moment(item?.joinPositionsDate).format("DD/MM/YYYY")}</td>
                                    <td>{item?.educationLevel}</td>
                                    <td>{getFormattedPhoneNum(item?.phoneNumber)}</td>
                                    <td></td>
                                </tr>
                            )
                        )
                    )}
                </table>
            </div>
        </div>
    );
}
