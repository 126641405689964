import { gql } from "@apollo/client";

export const CREATE_OFFICER = gql`
  mutation CreateOfficer($input: createOfficerInput!) {
    createOfficer(input: $input) {
      message
      status
    }
  }
`;


export const UPDATE_OFFICER = gql`
  mutation UpdateOfficer($input: updateOfficerInput!) {
    updateOfficer(input: $input) {
      message
      status
    }
  }
`;


export const DELETE_OFFICER = gql`
  mutation DeleteOfficer($id: String!) {
    deleteOfficer(_id: $id) {
      message
      status
    }
  }
`;

export const GET_OFFICER_WITH_PAGINATION = gql`
query GetOfficersPagination($page: Int!, $limit: Int!, $keyword: String!, $office: ID, $organization: ID, $department: ID) {
  getOfficersPagination(page: $page, limit: $limit, keyword: $keyword, office: $office, organization: $organization, department: $department) {
    message
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
    data {
      _id
      firstName
      lastName
      imageSrc
      imageName
      gender
      nationality
      phoneNumber
      email
      dateOfBirth
      placeOfBirth
      currentAddress
   
      officerId
      positions {
        _id
        name
      }
      organization {
        _id
        name
      }
      department {
        _id
        name
      }
      office {
        _id
        name
      }
      officerType
      educationLevel {
        degree
        major
        startDate
        endDate
        key
      }
      workHistory {
        positions
        organization
        startDate
        endDate
        key
      }
      biographyPartyOfficial {
        positions
        joinDate
        key
      }
      officerFramework {
        _id
        contractName
      }
      joiningDate
      fullPositionDate
      joinPositionsDate
      remark
      createdAt
    }
  }
}
`

export const GET_OFFICER_BY_OFFICE_PAGINATION = gql`
  query GetOfficersPagination($page: Int!, $limit: Int!, $keyword: String!, $office: ID!, $organization: ID!, $department: ID!) {
    getOfficersPagination(page: $page, limit: $limit, keyword: $keyword, office: $office, organization: $organization, department: $department) {
      data {
        _id
        biographyPartyOfficial {
          joinDate
          key
          positions
        }
        createdAt
        currentAddress
        dateOfBirth
        department {
          _id
          name
        }
        educationLevel {
          degree
          major
          startDate
          endDate
          key
        }
        email
        firstName
        gender
        imageName
        imageSrc
        lastName
        nationality
        office {
          _id
          name
        }
        officerId
        officerType
        organization {
          _id
          name
        }
        phoneNumber
        placeOfBirth
        positions {
          _id
          name
        }
        remark
        workHistory {
          positions
          organization
          startDate
          endDate
          key
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`;

export const GET_OFFICER_BY_ID = gql`
query GetOfficerById($officerId: ID) {
  getOfficerById(officerId: $officerId) {
    _id
    firstName
    lastName
    imageSrc
    imageName
    gender
    nationality
    phoneNumber
    email
    dateOfBirth
    placeOfBirth
    currentAddress
    joiningDate
    joinPositionsDate
    officerFramework {
      _id
      contractName
    }
   
    officerId
    positions {
      _id
      name
    }
    organization {
      _id
      name
    }
    department {
      _id
      name
    }
    office {
      _id
      name
    }
    officerType
    educationLevel {
      degree
      major
      startDate
      endDate
      key
    }
    workHistory {
      positions
      organization
      startDate
      endDate
      key
    }
    biographyPartyOfficial {
      positions
      joinDate
      key
    }
    remark
    createdAt
  }
}
`
