import "./officerinfo.scss";
import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Pagination,
  Select,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//icon from material
import Profile from "../Component/Menu/Profile";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
//component
import CreatePosition from "../Component/Position/CreatePosition/CreatePosition";
import PositionAction from "../Component/Position/PositionAction/PositionAction";
import { useQuery } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//draw
import Drawer from "@mui/material/Drawer";
import { GET_POSITION_WITH_PAGINATION } from "../Schema/position";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Position() {
  //handle open and close
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const drawerWidth = 420;

  // get Position
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_POSITION_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
    },
    onCompleted: ({ getPositionPagination }) => {
      // console.log("getPositionPagination::", getPositionPagination)
      setTableData(getPositionPagination?.data);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [page, limit, keyword]);

  return (
    <div className="officer-info-page">
      <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
        <Stack direction="column" justifyContent="center">
          <IconMenuResponsive />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
          <Typography className="page-title">
            <Link to="/setting" className="link-homepage">
              ការកំណត់
            </Link>{" "}
            / តំណែងមន្ត្រី
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Profile />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="left">
            <Box className="search-container">
              <TextField
                onChange={(e) => setKeyword(e.target.value)}
                className="text-field"
                fullWidth
                id="input-with-sx"
                placeholder="ស្វែងរក"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={handleOpen}
              startIcon={<BiLayerPlus />}
              className="btn-add"
            >
              បន្ថែមថ្មី
            </Button>

            <Drawer
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                },
              }}
              variant="persistent"
              anchor="right"
              open={open}
            >
              <CreatePosition
                setRefetch={refetch}
                handleClose={handleClose}
              />
            </Drawer>
          </Stack>
        </Grid>
      </Grid>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" width="80px">
                  ល.រ
                </TableCell>
                <TableCell className="header-title">តំណែងមន្ត្រី</TableCell>
                <TableCell className="header-title">លេខចំណាត់ថ្នាក់តំណែង</TableCell>
                <TableCell className="header-title">ផ្សេងៗ</TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody component={Paper} className="body">
              {tableData?.map((row, index) => (
                <TableRow className={(index + 1) % 2 === 1 ? "body-row" : "body-row-active"} key={index}>
                  <TableCell className="body-title"> {index + 1}</TableCell>
                  <TableCell className="body-title">{row?.name}</TableCell>
                  <TableCell className="body-title">{row?.positionsRanking}</TableCell>
                  <TableCell className="body-title">{row?.remark}</TableCell>
                  <TableCell className="body-title" align="right">
                    <PositionAction
                      editData={row}
                      setRefetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 , pb: 2}}>
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={data?.getPositionPagination?.paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>

          <Box width="150px" marginLeft={2} marginRight={2}>
            <FormControl fullWidth size="small">
              <Select value={limit} onChange={(e) => setLimit(e.target.value)}>
                <MenuItem value={10}>10 / page</MenuItem>
                <MenuItem value={20}>20 / page</MenuItem>
                <MenuItem value={50}>50 / page</MenuItem>
                <MenuItem value={100}>100 / page</MenuItem>
                <MenuItem value={200}>200 / page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}
