import { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
//graphql
import { GET_ALL_ORGANIZATION } from "../Schema/organization";
import { GET_DEPARTMENT_BY_ORGANIZATION } from "../Schema/department";
import { GET_OFFICE_BY_ORGANIZATION } from "../Schema/office";
import { GET_ALL_POSITION } from "../Schema/position";
import { GET_OFFICER_FRAMEWORK_PAGINATION , GET_ALL_OFFICER_FRAMEWORK } from "../Schema/officerframework";

export function SelectOrganization({ 
  selectedVal, 
  setSelectedVal, 
  setDepartmentVal,
  setOfficeVal,
  setPositionsVal, 
  className 
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false)

  const { refetch } = useQuery(GET_ALL_ORGANIZATION, {
    onCompleted: ({ getOrganizations }) => {
      // console.log("getOrganizations::", getOrganizations);
      let rows = [];
      if (getOrganizations?.length !== 0) {
        getOrganizations?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.name,
            logo: d?.imageSrc,
          };
          rows.push(allRows);
          setSelectOptions(rows);
        });
      } else {
        setSelectOptions([]);
      }
    },
  });

  useEffect(() => {
    refetch()
  }, [checkRefetch]);

  const handleSelect = (newVal) => {
    // set to local storage
    window.localStorage.setItem("organizSelected" , JSON.stringify(newVal) );
    window.localStorage.setItem("departSelected" , null );
    window.localStorage.setItem("offSelected" , null );

    // 
    setSelectedVal(newVal);
    setDepartmentVal({ id: "", title: "" })
    setOfficeVal({ id: "", title: "" })
    setPositionsVal({ id: "", title: "" })
    setCheckRefetch(!checkRefetch)    
    
  };


  return (
    <Autocomplete
      fullWidth
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.title === undefined ? "" : option.title}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="ជ្រើសរើសអង្គភាព" />
      )}
    />
  );
}

export function SelectDepartment({
  selectedVal,
  setSelectedVal,
  organizationId,
  className
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false)

  const { refetch } = useQuery(GET_DEPARTMENT_BY_ORGANIZATION, {
    variables: {
      organizationId: organizationId,
    },
    onCompleted: ({ getDepartmentsbyOrganization }) => {
      // console.log("getDepartmentsbyOrganization::", getDepartmentsbyOrganization);
      let rows = [];
      if (getDepartmentsbyOrganization?.data?.length !== 0) {
        getDepartmentsbyOrganization?.data?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.name,
          };
          rows.push(allRows);
          setSelectOptions(rows);
        });
      } else {
        setSelectOptions([]);
      }
    },
  });

  useEffect(() => {
    refetch()
  }, [checkRefetch, organizationId]);

  const handleSelect = (newVal) => {
    // set to local storage
    window.localStorage.setItem("departSelected" , JSON.stringify(newVal) );
    window.localStorage.setItem("offSelected" , null );
    // 
    setSelectedVal(newVal);
    setCheckRefetch(!checkRefetch)
  };


  return (
    <Autocomplete
      fullWidth
      className={className}
      disabled={organizationId === "" || organizationId === undefined ? true : false}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value }
      getOptionLabel={(option) =>
        option.title === undefined ? "" : option.title
      }
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="ជ្រើសរើសនាយកដ្ឋាន" />
      )}
    />
  );
}

export function SelectOffice({
  selectedVal,
  setSelectedVal,
  organizationId,
  departmentId,
  className
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);

  const { refetch } = useQuery(GET_OFFICE_BY_ORGANIZATION, {
    variables: {
      organizationId: organizationId,
      departmentId: departmentId === "" || departmentId === undefined ? null : departmentId,
    },
    onCompleted: ({ getOfficesbyDepOrOrg }) => {
      // console.log("getOfficesbyDepOrOrg::", getOfficesbyDepOrOrg)
      let rows = [];
      if (getOfficesbyDepOrOrg?.length !== 0) {
        getOfficesbyDepOrOrg?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.name,
          };
          rows.push(allRows);
          setSelectOptions(rows);
        });
      } else {
        setSelectOptions([]);
      }
    },
  });

  useEffect(() => {
    refetch()
  }, [checkRefetch, organizationId, departmentId]);

  const handleSelect = (newVal) => {
    // set to local storage
    window.localStorage.setItem("offSelected" , JSON.stringify(newVal) );
    // 
    setSelectedVal(newVal);
    setCheckRefetch(!checkRefetch)
  };

  return (
    <Autocomplete
      fullWidth
      className={className}
      disabled={organizationId === "" || organizationId === undefined ? true : false}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "" : option.title
      }
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="ជ្រើសរើសការិយាល័យ" />
      )}
    />
  );
}

export function SelectPosition({
  selectedVal,
  setSelectedVal,
  organizationId,
  departmentId,
  officeId,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false)

  const { refetch } = useQuery(GET_ALL_POSITION, {    
    onCompleted: ({ getPositions }) => {
      // console.log(getPositions);
      if (getPositions) {
        let row = [];
        getPositions?.forEach((element) => {
          const allRow = { id: element?._id, title: element?.name };
          row.push(allRow);
        });
        setSelectOptions(row);
      } else {
        setSelectOptions([])
      }
    },
  });

  useEffect(() => {
    refetch()
  }, [checkRefetch, organizationId, departmentId, officeId]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
    setCheckRefetch(!checkRefetch)
  };

  return (
    <Autocomplete
      disabled={organizationId === "" || organizationId === undefined ? true : false}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "" : option.title
      }
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="ជ្រើសរើសមុខតំណែង" />
      )}
    />
  );
}


export function SelectOfficeFramework({
  selectedVal,
  setSelectedVal,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false)

  const { refetch } = useQuery(GET_ALL_OFFICER_FRAMEWORK, {    
    onCompleted: ({ getContractsOfficers }) => {
      // console.log("getContractsOfficers::", getContractsOfficers);
      if (getContractsOfficers) {
        let row = [];
        getContractsOfficers?.forEach((element) => {
          const allRow = { id: element?._id, title: element?.contractName };
          row.push(allRow);
        });
        setSelectOptions(row);
      } else {
        setSelectOptions([])
      }
    },
  });

  useEffect(() => {
    refetch()
  }, [checkRefetch]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
    setCheckRefetch(!checkRefetch)
  };

  return (
    <Autocomplete
      value={selectedVal}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "" : option.title
      }
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="ជ្រើសរើសប្រភេទក្របខ័ណ្ឌ" />
      )}
    />
  );
} 