import React, { useState, useContext } from "react";
import "./createuser.scss";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Avatar,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { string, ref } from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import CropImageFile from "../../CropImage/CropImageFile";
import { CREATE_USER } from "../../../Schema/user";

export default function CreateUser({
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  // Create
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      setLoading(false);
      if (createUser?.status === true) {
        setAlert(true, "success", createUser?.message)
        setTimeout(() => {
          setRefetch();
        }, 1800);
        handleClose();
        resetForm();
      } else {
        setAlert(true, "error", createUser?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  // Formik
  const AddUser = Yup.object().shape({
    username: Yup.string().required("ទាមទារ!"),
    email: Yup.string().email("អ៊ីម៉ែលមិនត្រឹមត្រូវ!").required("ទាមទារ!"),
    position: Yup.string().required("ទាមទារ!"),
    password: Yup.string()
      .required("ទាមទារ!")
      .min(6, "សូមបញ្ចូលឲ្យលើស ៦ ខ្ទង់!"),
    confirmPassword: string()
      .required("ទាមទារ!")
      .oneOf([ref("password")], "បញ្ជាក់ពាក្យសម្ងាត់មិនត្រឹមត្រូវ!"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      position: "Admin",
      confirmPassword: "",
    },

    validationSchema: AddUser,
    onSubmit: (values) => {
      setLoading(true);
      const newUser = {
        username: values?.username,
        email: values?.email,
        password: values?.password,
        position: values?.position,
        photoName: profileHook,
        photoSrc: profileHook,
      };

      createUser({
        variables: {
          input: {
            ...newUser,
          },
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="create-user">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                បង្កើតអ្នកប្រើប្រាស់
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box width="100%">
                    <Stack direction="row" justifyContent="center">
                      <IconButton component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        <Avatar
                          className="avater-image"
                          src={
                            imageFile ? URL.createObjectURL(imageFile) : null
                          }
                        />
                        <input type="file" hidden />
                      </IconButton>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ mt: 1 }}
                    >
                      <Typography className="Sub-title">
                        រូបភាពអ្នកប្រើប្រាស់
                      </Typography>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setImageFile={setImageFile}
                    photoURL={photoURL}
                    setOpenCrop={setOpenCrop}
                    setPhotoURL={setPhotoURL}
                    setUploadHook={setProfileHook}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">
                  ឈ្មោះអ្នកប្រើប្រាស់
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ឈ្មោះអ្នកប្រើប្រាស់"
                  {...getFieldProps("username")}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">តួនាទី</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    {...getFieldProps("position")}
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="Admin">
                      <Typography>Admin</Typography>
                    </MenuItem>
                    <MenuItem value="Superadmin">
                      <Typography>Supper Admin</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">អ៊ីម៉ែល</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="អ៊ីម៉ែល"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">ពាក្យសម្ងាត់</Typography>
                <TextField
                  type={show ? "text" : "password"}
                  size="small"
                  fullWidth
                  placeholder="ពាក្យសម្ងាត់"
                  autoComplete="current-password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="icon" />
                        ) : (
                          <VisibilityOffIcon className="icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">
                  បញ្ជាក់ពាក្យសម្ងាត់
                </Typography>
                <TextField
                  type={show ? "text" : "password"}
                  name="confirmPassword"
                  size="small"
                  fullWidth
                  placeholder="បញ្ជាក់ពាក្យសម្ងាត់"
                  autoComplete="current-password"
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                  >
                    បង្កើតថ្មី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
