import React, { useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Radio, Stack, Button } from '@mui/material';
//Srcs
import "./pagedata.scss";
import { getKhmerNumber } from '../../../Function/DynamicFunction';
import familystatus from "../JsonData/familystatus.json"

export default function Familystatus({
  selectFamilyStatusData,
  setSelectFamilyStatusData
}) {

  // ============================ Resize width Screen ============================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    // console.log(window.innerWidth)
    if (window.innerWidth < 1536) {
      setWidth(window.innerWidth - 100);
    } else {
      setWidth(window.innerWidth - 320);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // change val1
  const handleUpdateData = (i, value) => {
    // console.log(i, value);
    let selectItem = selectFamilyStatusData;
    let arraySelected = familystatus?.filter((res, index) => index === i)

    // find array that already selected 
    let selected = selectItem?.filter((res, index) => res?.id === arraySelected[0]?.id);
    // console.log(selected)
    if (selected?.length !== 0) {
      selectItem.map((i) => {
        if (i.id === selected[0].id) {
          i.value = parseInt(value);
        }
      });
    } else {
      const newObj = {
        id: arraySelected[0]?.id,
        title: arraySelected[0]?.title,
        value: parseInt(value),
      }
      // console.log(newObj)
      selectItem.push(newObj)
    }
    setSelectFamilyStatusData([...selectItem]);
  };

  return (
    <div className="pagedata-style">
      <TableContainer sx={{ maxWidth: `${width}px` }}>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow className="header-row">
              <TableCell className="header-title" width="5%">ល.រ</TableCell>
              <TableCell className="header-title" width="45%">ចំណងជើង</TableCell>
              <TableCell className="header-title" width="10%">មិនល្អ(១)</TableCell>
              <TableCell className="header-title" width="10%">មធ្យម(២)</TableCell>
              <TableCell className="header-title" width="10%">ល្អបង្គួរ(៣)</TableCell>
              <TableCell className="header-title" width="10%">ល្អ(៤)</TableCell>
              <TableCell className="header-title" width="10%">ល្អណាស់(៥)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="body">
            {
              familystatus?.map((row, index) => {
                return (
                  <TableRow className={(index + 1) % 2 === 1 ? "body-row" : "body-row-active"} key={index} >
                    <TableCell className="body-title" width="5%">
                      {getKhmerNumber(index + 1)}
                    </TableCell>
                    <TableCell className="body-title" width="45%">
                      {row?.title}
                    </TableCell>
                    <TableCell className="body-title" width="10%">
                      <Radio
                        checked={selectFamilyStatusData[index]?.value === 1}
                        onChange={(e) => handleUpdateData(index, e.target.value)}
                        value={1}
                      />
                    </TableCell>

                    <TableCell className="body-title" width="10%">
                      <Radio
                        checked={selectFamilyStatusData[index]?.value === 2}
                        onChange={(e) => handleUpdateData(index, e.target.value)}
                        value={2}
                      />
                    </TableCell>

                    <TableCell className="body-title" width="10%">
                      <Radio
                        checked={selectFamilyStatusData[index]?.value === 3}
                        onChange={(e) => handleUpdateData(index, e.target.value)}
                        value={3}
                      />
                    </TableCell>

                    <TableCell className="body-title" width="10%">
                      <Radio
                        checked={selectFamilyStatusData[index]?.value === 4}
                        onChange={(e) => handleUpdateData(index, e.target.value)}
                        value={4}
                      />
                    </TableCell>

                    <TableCell className="body-title" width="10%">
                      <Radio
                        checked={selectFamilyStatusData[index]?.value === 5}
                        onChange={(e) => handleUpdateData(index, e.target.value)}
                        value={5}
                      />
                    </TableCell>

                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
