import React, { useState, useContext } from "react";
import "./createuser.scss";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";

//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import { CREATE_OFFICER_FRAMEWORK } from "../../../Schema/officerframework";

export default function CreateOfficeFramework({
  setRefetch,
  handleClose,
}) {

  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);


  // Create
  const [createContractsOfficer] = useMutation(CREATE_OFFICER_FRAMEWORK, {
    onCompleted: ({ createContractsOfficer }) => {      
      if (createContractsOfficer?.status === true) {
        setAlert(true, "success", createContractsOfficer?.message)
        handleClose();
        resetForm();
        setRefetch()
        setLoading(false);
      } else {
        setAlert(true, "error", createContractsOfficer?.message)
        setLoading(false);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  // Formik
  const addOfficeFramework = Yup.object().shape({
    contractName: Yup.string().required("ទាមទារ!"),   
    baseSalary: Yup.string(),    
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      contractName: "",
      baseSalary: "",
      remark: "",      
    },

    validationSchema: addOfficeFramework,
    onSubmit: (values) => {
      setLoading(true);
      const newOfficer = {
        contractName: values?.contractName,
        baseSalary: parseFloat(values?.baseSalary),
        remark: values?.remark,       
      };

      createContractsOfficer({
        variables: {
          input: newOfficer,
        },
      });

    },
  });

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="create-user">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                បង្កើតក្របខ័ណ្ឌមន្ត្រី
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">
                  ឈ្មោះក្របខ័ណ្ឌ
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ឈ្មោះក្របខ័ណ្ឌ"
                  {...getFieldProps("contractName")}
                  error={Boolean(touched.contractName && errors.contractName)}
                  helperText={touched.contractName && errors.contractName}
                />
              </Grid>

             
              <Grid item xs={12}>
                <Typography className="Sub-title">សន្ទស្សន៍បៀវត្ស</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="សន្ទស្សន៍បៀវត្ស"
                  {...getFieldProps("baseSalary")}
                  error={Boolean(touched.baseSalary && errors.baseSalary)}
                  helperText={touched.baseSalary && errors.baseSalary}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
              

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                  >
                    បង្កើតថ្មី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
