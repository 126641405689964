import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION = gql`
mutation CreateOrganization($name: String!, $remark: String, $imageSrc: String) {
  createOrganization(name: $name, remark: $remark, imageSrc: $imageSrc) {
    message
    status
  }
}
`;

export const UPDATE_ORGANIZATION = gql`
mutation UpdateOrganization($id: String!, $remark: String, $name: String!, $imageSrc: String) {
  updateOrganization(_id: $id, remark: $remark, name: $name, imageSrc: $imageSrc) {
    message
    status
  }
}
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: String!) {
    deleteOrganization(_id: $id) {
      message
      status
    }
  }
`;

export const GET_ALL_ORGANIZATION = gql`
  query GetOrganizations {
    getOrganizations {
      _id
      name
      imageSrc
    }
  }
`;

export const GET_ORGANIZATION_PAGINATION = gql`
  query GetOrganizationPagination(
    $page: Int!
    $limit: Int!
    $keyword: String!
  ) {
    getOrganizationPagination(page: $page, limit: $limit, keyword: $keyword) {
      data {
        _id
        name
        imageSrc
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`;
