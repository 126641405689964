import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  Autocomplete,
  Divider,
  FormHelperText
} from "@mui/material";
import "./updateofficer.scss";
import * as Yup from "yup";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import BackupIcon from '@mui/icons-material/Backup';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
// date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import provineCambodia from '../../../Assets/province.json';
import { SelectOrganization, SelectDepartment, SelectOffice, SelectPosition, SelectOfficeFramework } from "../../../Function/DynamicSelect";
import { UPDATE_OFFICER } from "../../../Schema/officer";
import CropImageFile from "../../CropImage/CropImageFile";
import ListEducation from "./ListEducation";
import ListExperince from "./ListExperince"
import ListPartyGroup from "./ListPartyGroup"

export default function UpdateOfficer({
  editData,
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [organizationVal, setOrganizationVal] = useState({ id: "", title: "" });
  const [departmentVal, setDepartmentVal] = useState({ id: "", title: "" })
  const [officeVal, setOfficeVal] = useState({ id: "", title: "" });
  const [positionsVal, setPositionsVal] = useState({ id: "", title: "" });
  const [officeFrameworkVal, setOfficeFrameworkVal] = useState({ id: "", title: "" });
  const [placeOfBirthSelected, setPlaceOfBirthSelected] = useState({})
  const [currentAddressSelected, setCurrentAddressSelected] = useState({})

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  // =========================================== List Education =========================================
  const [currentEducation, setCurrentEducation] = useState({ degree: "", major: "" , startDate: moment(), endDate: moment(), key: "" });
  const [educationList, setEducationList] = useState([]);

  const handleAddEducation = () => {
    setCurrentEducation({ degree: "បរិញ្ញាបត្រជាន់ខ្ពស់", major: "" , startDate: moment(), endDate: moment(), key: Date.now() });
  };

  const addEducation = () => {
    const newItem = currentEducation;
    if (newItem.key !== "") {
      const items = [...educationList, newItem];
      setEducationList([...items]);
      setCurrentEducation({ degree: "", major: "" , startDate: moment(), endDate: moment(), key: "" });
    }
  };

  React.useEffect(() => {
    if (currentEducation?.key !== "") {
      addEducation();
    }
  }, [currentEducation]);

  const deleteEducation = (key) => {
    const filteredItems = educationList?.filter((t) => t.key !== key);
    setEducationList(filteredItems);
  };

  const setUpdateDegree = (degree, key) => {
    const items = educationList;
    items.map((i) => {
      if (i.key === key) {
        i.degree = degree;
      }
    });
    setEducationList([...items]);
  };

  const setUpdateMajor = (major, key) => {
    const items = educationList;
    items.map((i) => {
      if (i.key === key) {
        i.major = major;
      }
    });
    setEducationList([...items]);
  };

  const setUpdateStartDate = (startDate, key) => {
    const items = educationList;
    items.map((i) => {
      if (i.key === key) {
        i.startDate = startDate;
      }
    });
    setEducationList([...items]);
  };

  const setUpdateEndDate = (endDate, key) => {
    const items = educationList;
    items.map((i) => {
      if (i.key === key) {
        i.endDate = endDate;
      }
    });
    setEducationList([...items]);
  };

  // =========================================== List Experience =========================================
  const [currentExperience, setCurrentExperience] = useState({ positions: "", organization: "", startDate: moment(), endDate: moment(), key: "" });
  const [experienceList, setExperinceList] = useState([]);

  const handleAddExperience = () => {
    setCurrentExperience({ positions: "", organization: "", startDate: moment(), endDate: moment(), key: Date.now() });
  };

  const addExperince = () => {
    const newItem = currentExperience;
    if (newItem.key !== "") {
      const items = [...experienceList, newItem];
      setExperinceList([...items]);
      setCurrentExperience({ positions: "", organization: "", startDate: moment(), endDate: moment(), key: "" });
    }
  };

  React.useEffect(() => {
    if (currentExperience?.key !== "") {
      addExperince();
    }
  }, [currentExperience]);

  const deleteExperience = (key) => {
    const filteredItems = experienceList?.filter((t) => t.key !== key);
    setExperinceList(filteredItems);
  };

  const setUpdatePositions = (positions, key) => {
    const items = experienceList;
    items.map((i) => {
      if (i.key === key) {
        i.positions = positions;
      }
    });
    setExperinceList([...items]);
  };

  const setUpdateOrganization = (organization, key) => {
    const items = experienceList;
    items.map((i) => {
      if (i.key === key) {
        i.organization = organization;
      }
    });
    setExperinceList([...items]);
  };

  const setUpdateStart = (startDate, key) => {
    const items = experienceList;
    items.map((i) => {
      if (i.key === key) {
        i.startDate = startDate;
      }
    });
    setExperinceList([...items]);
  };

  const setUpdateEnd = (endDate, key) => {
    const items = experienceList;
    items.map((i) => {
      if (i.key === key) {
        i.endDate = endDate;
      }
    });
    setExperinceList([...items]);
  };

  // =========================================== List party group =========================================
  const [currentPartyGroup, setCurrentPartyGroup] = useState({ positions: "", joinDate: "", key: "" });
  const [partyGroupList, setPartyGroupList] = useState([]);

  const handleAddPartyGroup = () => {
    setCurrentPartyGroup({ positions: "", joinDate: "", key: Date.now() });
  };

  const addPartyGroup = () => {
    const newItem = currentPartyGroup;
    if (newItem.key !== "") {
      const items = [...partyGroupList, newItem];
      setPartyGroupList([...items]);
      setCurrentPartyGroup({ positions: "", joinDate: "", key: "" });
    }
  };

  React.useEffect(() => {
    if (currentPartyGroup?.key !== "") {
      addPartyGroup();
    }
  }, [currentPartyGroup]);

  const deletePartyGroup = (key) => {
    const filteredItems = partyGroupList?.filter((t) => t.key !== key);
    setPartyGroupList(filteredItems);
  };

  const setUpdatePartyPositions = (positions, key) => {
    const items = partyGroupList;
    items.map((i) => {
      if (i.key === key) {
        i.positions = positions;
      }
    });
    setPartyGroupList([...items]);
  };

  const setUpdateJoinDate = (joinDate, key) => {
    const items = partyGroupList;
    items.map((i) => {
      if (i.key === key) {
        i.joinDate = joinDate;
      }
    });
    setPartyGroupList([...items]);
  };
  // =========================================== End list select =========================================

  const [updateOfficer] = useMutation(UPDATE_OFFICER, {
    onCompleted: ({ updateOfficer }) => {
      setLoading(false);
      // console.log("updateOfficer::", updateOfficer)
      if (updateOfficer?.status === true) {
        setAlert(true, "success", updateOfficer?.message)
        setRefetch();
        handleClose();
      } else {
        setAlert(true, "error", updateOfficer?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
    },
  });

  const UpdateOfficer = Yup.object().shape({
    firstName: Yup.string().required("ទាមទារ!"),
    lastName: Yup.string().required("ទាមទារ!"),
    gender: Yup.string().required("ទាមទារ!"),
    dateOfBirth: Yup.date(),
    joiningDate: Yup.date(),
    fullPositionDate: Yup.date(),
    joinPositionsDate: Yup.date(),
    placeOfBirth: Yup.string(),
    currentAddress: Yup.string(),
    // nationalId: Yup.string(),
    officerId: Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string(),
    nationality: Yup.string(),
    officerType: Yup.string().required("ទាមទារ!"),
    officerFramework: Yup.string(),

    organization: Yup.string().required("ទាមទារ!"),
    department: Yup.string(),
    office: Yup.string(),
    positions: Yup.string().required("ទាមទារ!"),
    remark: Yup.string(),
  });

  // empty field
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "ប្រុស",
      dateOfBirth: moment(),
      joiningDate: moment(),
      fullPositionDate: moment(),
      joinPositionsDate: moment(),
      placeOfBirth: "",
      currentAddress: "",
      // nationalId: "",
      officerId: "",
      phoneNumber: "",
      email: "",
      nationality: "ខ្មែរ",
      officerType: "មន្រី្តថ្នាក់ជាតិ",
      officerFramework: "",

      organization: "",
      department: "",
      office: "",
      positions: "",
      remark: "",
    },

    validationSchema: UpdateOfficer,

    onSubmit: (values) => {
      console.log("values::", values);
      setLoading(true);
      updateOfficer({
        variables: {
          input: {
            _id: editData?._id,
            ...values,
            imageName: "Officeer Profile",
            imageSrc: profileHook,
            department: departmentVal?.id ? departmentVal?.id : null,
            office: officeVal?.id ? officeVal?.id : null,
            educationLevel: educationList,
            workHistory: experienceList,
            biographyPartyOfficial: partyGroupList,
            officerFramework: officeFrameworkVal?.id === "" || officeFrameworkVal?.id === undefined ? null : officeFrameworkVal?.id,
          },
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, resetForm, } = formik;

  // console.log("editData::", editData)

  useEffect(() => {
    if (editData) {
      setProfileHook(editData?.imageSrc);
      setFieldValue("firstName", editData?.firstName)
      setFieldValue("lastName", editData?.lastName)
      setFieldValue("gender", editData?.gender)
      setFieldValue("dateOfBirth", editData?.dateOfBirth)
      setFieldValue("joiningDate", editData?.joiningDate === null ? "" : editData?.joiningDate)
      setFieldValue("fullPositionDate", editData?.fullPositionDate === null || editData?.fullPositionDate === undefined ? "" : editData?.fullPositionDate)
      setFieldValue("joinPositionsDate", editData?.joinPositionsDate === null ? "" : editData?.joinPositionsDate)
      setFieldValue("placeOfBirth", editData?.placeOfBirth)
      setPlaceOfBirthSelected({ title: editData?.placeOfBirth })
      setFieldValue("currentAddress", editData?.currentAddress)
      setCurrentAddressSelected({ title: editData?.currentAddress })
      // setFieldValue("nationalId", editData?.nationalId)
      setFieldValue("officerId", editData?.officerId)
      setFieldValue("phoneNumber", editData?.phoneNumber)
      setFieldValue("email", editData?.email)
      setFieldValue("nationality", editData?.nationality)
      setFieldValue("officerType", editData?.officerType)
      setFieldValue("remark", editData?.remark)

      if (editData?.organization?._id) {
        // set Organization
        setFieldValue("organization", editData?.organization?._id)
        setOrganizationVal({ id: editData?.organization?._id, title: editData?.organization?.name })
        // set department
        setFieldValue("department", editData?.department?._id)
        setDepartmentVal({ id: editData?.department?._id, title: editData?.department?.name })
        // set office
        setFieldValue("office", editData?.office?._id)
        setOfficeVal({ id: editData?.office?._id, title: editData?.office?.name })
        // get postion data                
        setFieldValue("positions", editData?.positions?._id)
        setPositionsVal({ id: editData?.positions?._id, title: editData?.positions?.name })
      }

      if (editData?.officerFramework?._id) {
        // set officeframeword
        setFieldValue("officerFramework", editData?.officerFramework?._id)
        setOfficeFrameworkVal({ id: editData?.officerFramework?._id, title: editData?.officerFramework?.contractName })
      }

      if (editData?.educationLevel?.length !== 0) {
        let rows = [];
        editData?.educationLevel?.map((d) => {
          const allRows = {
            degree: d?.degree,
            major: d?.major,
            endDate: d?.endDate,
            startDate: d?.startDate,
            key: d?.key
          };
          rows.push(allRows);
          setEducationList(rows);
        });
      }

      if (editData?.workHistory?.length !== 0) {
        let rows = [];
        editData?.workHistory?.map((d) => {
          const allRows = {
            organization: d?.organization,
            positions: d?.positions,
            startDate: d?.startDate,
            endDate: d?.endDate,
            key: d?.key
          };
          rows.push(allRows);
          setExperinceList(rows);
        });
      }

      if (editData?.biographyPartyOfficial?.length !== 0) {
        let rows = [];
        editData?.biographyPartyOfficial?.map((d) => {
          const allRows = {
            positions: d?.positions,
            joinDate: d?.joinDate,
            key: d?.key
          };
          rows.push(allRows);
          setPartyGroupList(rows);
        });
      }
    }
  }, [editData])

  useEffect(() => {
    if (organizationVal?.id !== undefined) {
      setFieldValue("organization", organizationVal?.id)
    } else {
      setFieldValue("organization", "")
    }
  }, [organizationVal?.id])

  useEffect(() => {
    if (departmentVal?.id !== undefined) {
      setFieldValue("department", departmentVal?.id)
    } else {
      setFieldValue("department", "")
    }
  }, [departmentVal?.id])

  useEffect(() => {
    if (officeVal?.id !== undefined) {
      setFieldValue("office", officeVal?.id)
    } else {
      setFieldValue("office", "")
    }
  }, [officeVal?.id])

  useEffect(() => {
    if (positionsVal?.id !== undefined) {
      setFieldValue("positions", positionsVal?.id)
    } else {
      setFieldValue("positions", "")
    }
  }, [positionsVal?.id])
  // console.log("provineCambodia::", provineCambodia)

  // console.log(values?.joiningDate)

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="update-officer">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                ព័ត៌មានមន្ត្រី
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container rowSpacing={1.5} columnSpacing={3} sx={{ marginTop: "2px" }}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <Typography className="info-title">ព័ត៌មានផ្ទាល់ខ្លួន</Typography>
                  <Stack direction="column" justifyContent="center" width="100%">
                    <Divider sx={{ color: "#0f81c2" }} />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="Sub-title">អត្តលេខមន្រ្តីរាជការ</Typography>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="អត្តលេខមន្រ្តីរាជការ"
                      {...getFieldProps("officerId")}
                      error={Boolean(touched.officerId && errors.officerId)}
                      helperText={touched.officerId && errors.officerId}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="Sub-title">គោត្តនាម</Typography>
                    <TextField
                      className="text-field"
                      size="small"
                      fullWidth
                      placeholder="គោត្តនាម"
                      {...getFieldProps("firstName")}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="Sub-title">នាម​</Typography>
                    <TextField
                      className="text-field"
                      size="small"
                      fullWidth
                      placeholder="នាម"
                      {...getFieldProps("lastName")}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="Sub-title">ភេទ</Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={values?.gender}
                        onChange={(e) => setFieldValue("gender", e.target.value)}
                        row
                      >
                        <FormControlLabel value="ប្រុស" control={<Radio />} label="ប្រុស" />
                        <FormControlLabel value="ស្រី" control={<Radio />} label="ស្រី" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="Sub-title">
                      ថ្ងៃ-ខែ-ឆ្នាំ កំណើត
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd-MM-yyyy"
                          mask="__-__-____"
                          value={values?.dateOfBirth}
                          onChange={(e) => setFieldValue("dateOfBirth", e)}
                          renderInput={(params) => (
                            <TextField
                              className="text-field"
                              {...params}
                              placeholder="ថ្ងៃ ខែ ឆ្នាំ កំណើត"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                {!openCrop ? (
                  <Stack direction="column" justifyContent="center" className="box-upload-image">
                    {
                      profileHook === "" ?
                        <>
                          <Stack direction="row" justifyContent="center">
                            <Button component="label">
                              <TextField
                                type="file"
                                id="image"
                                sx={{ display: "none" }}
                                onChange={handleUploadImage}
                              />
                              <BackupIcon />
                              {/* <img alt="Image" src={profileHook} className="image-uploaded" /> */}
                              <input type="file" hidden />
                            </Button>
                          </Stack>
                          <Typography className="upload-text">បញ្ចូលរូបភាព</Typography>
                        </>
                        :
                        <>
                          <Stack direction="row" justifyContent="center">
                            <Button component="label">
                              <TextField
                                type="file"
                                id="image"
                                sx={{ display: "none" }}
                                onChange={handleUploadImage}
                              />
                              <img alt="Image" src={imageFile ? URL.createObjectURL(imageFile) : `${process.env.REACT_APP_IMAGE_SERVER}fileName:${editData?.imageSrc + process.env.REACT_APP_IMAGE_URL}`} className="image-uploaded" />
                              <input type="file" hidden />
                            </Button>
                          </Stack>
                          <Typography className="upload-text">បញ្ចូលរូបភាព</Typography>
                        </>
                    }
                  </Stack>
                ) : (
                  <CropImageFile
                    setImageFile={setImageFile}
                    photoURL={photoURL}
                    setOpenCrop={setOpenCrop}
                    setPhotoURL={setPhotoURL}
                    setUploadHook={setProfileHook}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">
                  ថ្ងៃ-ខែ-ឆ្នាំ ចូលបម្រើការងារ
                </Typography>
                <Stack direction="row" spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      mask="__-__-____"
                      value={values?.joiningDate}
                      onChange={(e) => {                       
                        e !== null ? setFieldValue("joiningDate", e) : setFieldValue("joiningDate", "")
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          {...params}
                          placeholder="ថ្ងៃ ខែ ឆ្នាំ ចូលមប្រើការងារ"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">
                  ថ្ងៃ-ខែ-ឆ្នាំ តាំងស៊ប់
                </Typography>
                <Stack direction="row" spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      mask="__-__-____"
                      value={values?.fullPositionDate}
                      onChange={(e) => {                       
                        e !== null ? setFieldValue("fullPositionDate", e) : setFieldValue("fullPositionDate", "")
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          {...params}
                          placeholder="ថ្ងៃ ខែ ឆ្នាំ តាំងស៊ប់"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">ប្រភេទក្របខ័ណ្ឌ</Typography>
                <SelectOfficeFramework
                  selectedVal={officeFrameworkVal}
                  setSelectedVal={setOfficeFrameworkVal}
                />
              </Grid>

              
              <Grid item xs={6}>
                <Typography className="Sub-title">អង្គភាព</Typography>
                <SelectOrganization
                  selectedVal={organizationVal}
                  setSelectedVal={setOrganizationVal}
                  setDepartmentVal={setDepartmentVal}
                  setOfficeVal={setOfficeVal}
                  setPositionsVal={setPositionsVal}
                />
                {!!errors.organization && touched.organization && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.organization}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">នាយកដ្ឋាន</Typography>
                <SelectDepartment
                  selectedVal={departmentVal}
                  setSelectedVal={setDepartmentVal}
                  organizationId={organizationVal?.id}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">ការិយាល័យ</Typography>
                <SelectOffice
                  selectedVal={officeVal}
                  setSelectedVal={setOfficeVal}
                  organizationId={organizationVal?.id}
                  departmentId={departmentVal?.id}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">មុខតំណែង</Typography>
                <SelectPosition
                  selectedVal={positionsVal}
                  setSelectedVal={setPositionsVal}
                  organizationId={organizationVal?.id}
                  departmentId={departmentVal?.id}
                  officeId={officeVal?.id}
                />
                {!!errors.positions && touched.positions && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.positions}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">
                  ថ្ងៃ-ខែ-ឆ្នាំ ចូលកាន់មុខតំណែង
                </Typography>
                <Stack direction="row" spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      mask="__-__-____"
                      value={values?.joinPositionsDate}
                      onChange={(e) => {
                        e !== null ? setFieldValue("joinPositionsDate", e) : setFieldValue("joinPositionsDate", "")                       
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          {...params}
                          placeholder="ថ្ងៃ ខែ ឆ្នាំ ចូលកាន់មុខតំណែង"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">ប្រភេទមន្រ្តី</Typography>
                <FormControl
                  className="form-controll-select"
                  size="small"
                  fullWidth
                >
                  <Select
                    IconComponent={(props) => (
                      <KeyboardArrowDownOutlinedIcon {...props} />
                    )}
                    {...getFieldProps("officerType")}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                      <MenuItem value="មន្រី្តថ្នាក់ជាតិ">
                        <Typography>មន្រី្តថ្នាក់ជាតិ</Typography>
                      </MenuItem>
                      <MenuItem value="មន្រី្តថ្នាក់ខេត្ត">
                        <Typography>មន្រី្តថ្នាក់ខេត្ត</Typography>
                      </MenuItem>
                      <MenuItem value="មន្រី្តថ្នាក់ស្រុក">
                        <Typography>មន្រី្តថ្នាក់ស្រុក</Typography>
                      </MenuItem>
                      <MenuItem value="មន្រី្តថ្នាក់ឃុំ">
                        <Typography>មន្រី្តថ្នាក់ឃុំ</Typography>
                      </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/*========================================= កម្រិតវប្បធម៌ទូទៅ ========================================= */}
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} height="30px">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="info-title">កម្រិតវប្បធម៌ទូទៅ</Typography>
                  </Stack>

                  <Stack direction="column" justifyContent="center" width="100%">
                    <Divider sx={{ color: "#0f81c2" }} />
                  </Stack>
                  <Stack direction="column" justifyContent="center">
                    <IconButton sx={{ width: 30, height: 30, color: "#0f81c2" }} onClick={handleAddEducation}>
                      <ControlPointIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <ListEducation
                  items={educationList}
                  deleteEducation={deleteEducation}
                  setUpdateDegree={setUpdateDegree}
                  setUpdateMajor={setUpdateMajor}
                  setUpdateStartDate={setUpdateStartDate}
                  setUpdateEndDate={setUpdateEndDate}
                />
              </Grid>

              {/* <Grid item xs={6}>
                <Typography className="Sub-title">
                  ទីកន្លែងកំណើត
                </Typography>
                <Autocomplete
                  size="small"
                  options={provineCambodia}
                  getOptionLabel={(option) => option.title ? option.title : ""}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  onChange={(option, value) => setFieldValue("placeOfBirth", value?.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="ជ្រើសរើស"
                      error={Boolean(touched.placeOfBirth && errors.placeOfBirth)}
                      helperText={touched.placeOfBirth && errors.placeOfBirth}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">
                  អាសយដ្នានបច្ចុប្បន្ន
                </Typography>
                <Autocomplete
                  size="small"
                  options={provineCambodia}
                  getOptionLabel={(option) => option.title ? option.title : ""}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  onChange={(option, value) => setFieldValue("currentAddress", value?.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="ជ្រើសរើស"
                      error={Boolean(touched.currentAddress && errors.currentAddress)}
                      helperText={touched.currentAddress && errors.currentAddress}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">អត្តសញ្ញាណប័ណ្ណ</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="អត្តសញ្ញាណប័ណ្ណ"
                  {...getFieldProps("nationalId")}
                  error={Boolean(touched.nationalId && errors.nationalId)}
                  helperText={touched.nationalId && errors.nationalId}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">អ៊ីម៉ែល</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="អ៊ីម៉ែល"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid> */}

              
              

              <Grid item xs={6}>
                <Typography className="Sub-title">លេខទូរស័ព្ទ</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="លេខទូរស័ព្ទ"
                  {...getFieldProps("phoneNumber")}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>

              {/*========================================= ប្រវត្តិការងារ ========================================= */}
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} height="30px">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="info-title">ប្រវត្តិការងារ</Typography>
                  </Stack>

                  <Stack direction="column" justifyContent="center" width="100%">
                    <Divider sx={{ color: "#0f81c2" }} />
                  </Stack>
                  <Stack direction="column" justifyContent="center">
                    <IconButton sx={{ width: 30, height: 30, color: "#0f81c2" }} onClick={handleAddExperience}>
                      <ControlPointIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <ListExperince
                  items={experienceList}
                  deleteExperience={deleteExperience}
                  setUpdatePositions={setUpdatePositions}
                  setUpdateOrganization={setUpdateOrganization}
                  setUpdateStart={setUpdateStart}
                  setUpdateEnd={setUpdateEnd}
                />
              </Grid>

              {/*========================================= ជីវប្រវត្តិសង្ខេបមន្ត្រីគណបក្ស ========================================= */}
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} height="30px">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="info-title">ជីវប្រវត្តិសង្ខេបមន្ត្រីគណបក្ស</Typography>
                  </Stack>

                  <Stack direction="column" justifyContent="center" width="100%">
                    <Divider sx={{ color: "#0f81c2" }} />
                  </Stack>
                  <Stack direction="column" justifyContent="center">
                    <IconButton sx={{ width: 30, height: 30, color: "#0f81c2" }} onClick={handleAddPartyGroup}>
                      <ControlPointIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <ListPartyGroup
                  items={partyGroupList}
                  deletePartyGroup={deletePartyGroup}
                  setUpdatePartyPositions={setUpdatePartyPositions}
                  setUpdateJoinDate={setUpdateJoinDate}
                />
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                {loading ? (
                  <Button className="btn-update" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-update"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                    startIcon={<BiLayerPlus />}
                  >
                    កែប្រែ
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
