import React, { useContext , useState } from "react";
import "./deleteuser.scss";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import { DELETE_OFFICER_FRAMEWORK } from "../../../Schema/officerframework";

export default function DeleteOfficerFramework({
  editData,
  setRefetch,
  handleClose
}) {

  const [loading,setLoading] = useState(false);

  const { setAlert } = useContext(AuthContext);
  const [valueVoid, setValueVoid] = React.useState("");

  const [deleteContractsOfficer] = useMutation(DELETE_OFFICER_FRAMEWORK, {
    onCompleted: ({ deleteContractsOfficer }) => {
      if (deleteContractsOfficer?.status) {
        setAlert(true, "success", deleteContractsOfficer?.message)
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", deleteContractsOfficer?.message)
        setLoading(false);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  const handleDelete = () => {
      setLoading(true);
      deleteContractsOfficer({
        variables: {
          deleteContractsOfficerId: editData?._id,
        },
      });
  };

  return (
    <Box className="delete-user">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          លុបក្របខ័ណ្ឌមន្រ្តី
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>

        <IconButton onClick={handleClose}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          តើអ្នកចង់លុបទិន្នន័យក្របខ័ណ្ឌមន្រ្តីមែនទេ?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          សូមបញ្ចូល
        </Typography>

        <Typography variant="subtitle1" className="body-text">
          {editData?.contractName}
        </Typography>

        <Typography variant="subtitle1" className="simple-text">
          ដើម្បីលុប
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {valueVoid === editData?.contractName ? (
          <>
            {
                loading ?
                  <Button
                    className="btn-void"                
                    variant="outlined"
                    fullWidth
                  >
                    រង់ចាំ
                  </Button>
              :
                  <Button
                    className="btn-void"
                    onClick={handleDelete}
                    variant="outlined"
                    fullWidth
                  >
                    លុបឥឡូវ
                  </Button>
                  
              }
          </>
        ) : (
          <Button className="btn-delete" variant="outlined" fullWidth>
            លុប
          </Button>
        )}
      </Stack>
    </Box>
  );
}

