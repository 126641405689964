import React, { useEffect, useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Typography, Box, Grid, TextField, IconButton, Autocomplete, InputAdornment } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { GET_BONUS_TYPE } from "../../Schema/Setting";

export default function ListExperince(props) {

    // get Bonus Type =====================================================================
    const [optionBonus, setOptionBonus] = useState([]);

    const items = props.items;
    const listItems = items.map((item) => {
        return (
            <Box key={item.key} sx={{ mb: 2 }}>
                <Grid item container spacing={2}>
                    <Grid item xs={3}>
                        <Typography className="Sub-title">មុខតំណែង</Typography>
                        <TextField
                            className="text-field"
                            size="small"
                            fullWidth
                            placeholder="មុខតំណែង"
                            value={item?.positions}
                            onChange={(e) => props.setUpdatePositions(e.target.value, item?.key)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="Sub-title">អង្គភាព</Typography>
                        <TextField
                            className="text-field"
                            size="small"
                            fullWidth
                            placeholder="អង្គភាព"
                            value={item?.organization}
                            onChange={(e) => props.setUpdateOrganization(e.target.value, item?.key)}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <Typography className="Sub-title">ចាប់ផ្ដើម</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={item?.startDate}
                                onChange={(e) => props.setUpdateStart(e, item?.key)}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        className="text-field"
                                        {...params}
                                        placeholder="This Month"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Typography className="Sub-title">បញ្ចប់</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={item?.endDate}
                                onChange={(e) => props.setUpdateEnd(e, item?.key)}
                                inputFormat="dd-MM-yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        className="text-field"
                                        {...params}
                                        placeholder="This Month"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <IconButton
                            sx={{ cursor: "pointer", marginTop: "20px" }}
                            onClick={() => {
                                props.deleteExperience(item.key);
                            }}
                        >
                            <DeleteRoundedIcon sx={{ color: "red" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        );
    });
    return listItems;
}
