import React, { useContext } from "react";
import "./deleteoffice.scss";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import { DELETE_OFFICE } from "../../../Schema/office";

function DeleteOffice({
  editData,
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [valueVoid, setValueVoid] = React.useState("");
  const [deleteOffice] = useMutation(DELETE_OFFICE, {
    onCompleted: ({ deleteOffice }) => {
      if (deleteOffice?.status) {
        setAlert(true, "success", deleteOffice?.message)
        setRefetch();
        handleClose();
        setValueVoid("")
      } else {
        setAlert(true, "error", deleteOffice?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
    },
  });

  const handleDelete = () => {
    deleteOffice({
      variables: {
        id: editData?._id,
      },
    });
  };

  return (
    <Box className="delete-organization">
      <Stack direction="row" spacing={5}>
        <Typography className="title" variant="h6">
          លុបការិយាល័យ
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>

        <IconButton onClick={handleClose}>
          <ClearIcon sx={{ color: "red" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={5} width="100%">
        <Typography variant="subtitle1" className="simple-text">
          តើអ្នកចង់លុបទិន្នន័យការិយាល័យមែនទេ?
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mt: 2 }}
      >
        <Typography variant="subtitle1" className="simple-text">
          សូមបញ្ចូល
        </Typography>

        <Typography variant="subtitle1" className="body-text">
          {editData?.name}
        </Typography>

        <Typography variant="subtitle1" className="simple-text">
          ដើម្បីលុប
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        width="100%"
        sx={{ mb: 4 }}
      >
        <TextField
          className="text-input"
          size="small"
          fullWidth
          onChange={(e) => setValueVoid(e.target.value)}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {valueVoid === editData?.name ? (
          <Button
            className="btn-void"
            onClick={handleDelete}
            variant="outlined"
            fullWidth
          >
            លុបឥឡូវ
          </Button>
        ) : (
          <Button className="btn-delete" variant="outlined" fullWidth>
            លុប
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default DeleteOffice;
