import React, { useState } from "react";
import { Stack, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import "./useraction.scss";
import Drawer from "@mui/material/Drawer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

//component
import UpdateOfficeFramework from "../UpdateOfficeFramework/UpdateOfficeFramework";
import DeleteOfficerFramework from "../DeleteOfficerFramework/DeleteOfficerFramework";

export default function FrameworkAction({
  editData,
  setRefetch,
}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const openEl = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEl = () => {
    setAnchorEl(null);
  };

  // console.log("organizationId::",organizationId)

  const drawerWidth = 350;
  const [open, setOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseDel = () => setOpenDel(false);

  const handleOpenDel = () => {
    setOpenDel(true);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3d3d3d" }} />
      </IconButton>

      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={openEl}
        onClose={handleCloseEl}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpen();
            handleCloseEl();
          }}
        >
          <Stack direction="row" spacing={1}>
            <AppRegistrationOutlinedIcon className="icon-update" />
            <Typography className="text-action"> កែរប្រែ </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleCloseEl();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteOutlineOutlinedIcon className="icon-delete" />
            <Typography className="text-action"> លុប </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <UpdateOfficeFramework
          editData={editData}
          setRefetch={setRefetch}
          handleClose={handleClose}
          open={open}
        />
      </Drawer>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDel}
      >
        <DeleteOfficerFramework
          editData={editData}
          setRefetch={setRefetch}
          handleClose={handleCloseDel}
        />
      </Drawer>
    </>
  );
}
