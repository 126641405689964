import * as React from "react";
import {
    Box,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import "./dashboard.scss";
//Component
import Profile from "../Component/Menu/Profile";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import BlockHeader from "../Component/Dashboard/BlockHeader/BlockHeader";
import EvaluateOfficers from "../Component/Dashboard/EvaluateOfficers/EvaluateOfficers"
import OfficersByorganization from "../Component/Dashboard/OfficersByOrganization/OfficersByorganization";
import EducationOfficers from "../Component/Dashboard/EducationOfficers/EducationOfficers";

export default function Dashboard() {

    return (
        <div className="dashboard-page">
            <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
                <Stack direction="column" justifyContent="center">
                    <IconMenuResponsive />
                </Stack>
                <Stack direction="column" justifyContent="center">
                    <Box className="slash" />
                </Stack>
                <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
                    <Typography className="color">ទំព័រដើម</Typography>
                </Stack>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Profile />
            </Stack>

            <Box className="container">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <BlockHeader />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <OfficersByorganization />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* <EvaluateOfficers /> */}
                        <EducationOfficers />
                    </Grid>
                </Grid>


                {/* <Grid container spacing={4}>

                    <Grid container item xs={12} sm={12} md={12} lg={8} xl={8} spacing={4}>

                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
                            <Stack direction="row" justifyContent="center" className="grid-item">
                                <Stack direction="row" justifyContent="right" width="100%" padding={2}>
                                    <Box className="background-image" />
                                </Stack>
                                <Stack className="body-text" spacing={1}>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Stack direction="row" justifyContent="right">
                                        <Typography className="title">មន្ត្រីសកម្ម</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="right" spacing={1}>
                                        <Typography className="body">236</Typography>
                                        <Typography className="body-small">នាក់</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
                            <Stack direction="row" justifyContent="center" className="grid-item">
                                <Stack direction="row" justifyContent="right" width="100%">
                                    <Box className="background-flat" />
                                </Stack>
                                <Stack className="body-text" spacing={1}>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Stack direction="row" justifyContent="right">
                                        <Typography className="title">មន្ត្រីផ្ទេចេញ</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="right" spacing={1}>
                                        <Typography className="body">236</Typography>
                                        <Typography className="body-small">នាក់</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
                            <Stack direction="row" justifyContent="center" className="grid-item">
                                <Stack direction="row" justifyContent="right" width="100%">
                                    <Box className="background-angkor" />
                                </Stack>
                                <Stack className="body-text" spacing={1}>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Stack direction="row" justifyContent="right">
                                        <Typography className="title">ចំនួនអង្គភាព</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="right" spacing={1}>
                                        <Typography className="body">236</Typography>
                                        <Typography className="body-small">នាក់</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <ChartOfficer />
                        </Grid>

                    </Grid>

                    <Grid container item xs={12} sm={12} md={12} lg={4} xl={4} spacing={4}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Stack direction="row" justifyContent="center" className="grid-item">
                                <Stack className="body-text" spacing={1}>
                                    <Stack direction="row" justifyContent="right">
                                        <Typography className="title">មន្ត្រីសរុបតាមអង្គភាព</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="right" spacing={1}>
                                        <Typography className="body">236</Typography>
                                        <Typography className="body-small">នាក់</Typography>
                                    </Stack>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Stack direction="row" justifyContent="left">
                                        <Stack direction="column" justifyContent="center">
                                            <FiberManualRecordIcon sx={{ color: '#FFF678' }} />
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography className="total-male-bold">ប្រុស៖</Typography>
                                            <Typography className="total-male">3900 នាក់</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" justifyContent="left">
                                        <Stack direction="column" justifyContent="center">
                                            <FiberManualRecordIcon sx={{ color: 'red' }} />
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography className="total-female-bold">ស្រី៖</Typography>
                                            <Typography className="total-female">2900 នាក់</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <OfficerByDistrict />
                        </Grid>

                    </Grid>
                </Grid> */}
            </Box>

        </div>
    );
}