import { gql } from "@apollo/client";

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment(
    $name: String!
    $remark: String!
    $organization: ID!
  ) {
    createDepartment(
      name: $name
      remark: $remark
      organization: $organization
    ) {
      message
      status
    }
  }
`;

export const GET_DEPARTMENT_BY_ORGANIZATION_PAGINATION = gql`
  query GetDepartmentPagination(
    $page: Int!
    $keyword: String!
    $organizationId: ID!
    $limit: Int!
  ) {
    getDepartmentPagination(
      page: $page
      keyword: $keyword
      organizationId: $organizationId
      limit: $limit
    ) {
      data {
        _id
        name
        organization {
          _id
          name
          remark
          createdAt
        }
        remark
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      message
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment(
    $id: String!
    $name: String!
    $remark: String!
    $organization: ID!
  ) {
    updateDepartment(
      _id: $id
      name: $name
      remark: $remark
      organization: $organization
    ) {
      message
      status
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($id: String!) {
    deleteDepartment(_id: $id) {
      message
      status
    }
  }
`;

export const GET_DEPARTMENT_BY_ORGANIZATION = gql`
  query GetDepartmentsbyOrganization($organizationId: ID!) {
    getDepartmentsbyOrganization(organizationId: $organizationId) {
      data {
        _id
        name        
      }
      message
      status
    }
  }
`;
