import { gql } from "@apollo/client";

export const CREATE_POSITION = gql`
mutation CreatePosition($name: String!, $positionsRanking: Int, $remark: String) {
  createPosition(name: $name, positionsRanking: $positionsRanking, remark: $remark) {
    message
    status
  }
}
`;

export const UPDATE_POSITION = gql`
mutation UpdatePosition($id: String!, $name: String, $positionsRanking: Int, $remark: String) {
  updatePosition(_id: $id, name: $name, positionsRanking: $positionsRanking, remark: $remark) {
    message
    status
  }
}
`;

export const DELETE_POSITION = gql`
  mutation DeletePosition($id: String!) {
    deletePosition(_id: $id) {
      message
      status
    }
  }
`;

export const GET_LAST_POSITIONS_RANKING = gql`
query Query {
  getLastPositionsRanking
}
`

export const GET_ALL_POSITION = gql`
query GetPositions {
  getPositions {
    _id
    createdAt
    name
    positionsRanking
    remark
  }
}
`;

export const GET_POSITION_WITH_PAGINATION = gql`
query GetPositionPagination($page: Int!, $limit: Int!, $keyword: String!) {
  getPositionPagination(page: $page, limit: $limit, keyword: $keyword) {
    data {
      _id
      name
      positionsRanking
      remark
      createdAt
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
    message
  }
}
`;

