import {
  Box,
  Button,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
  TextField,
  InputAdornment,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  Grid,
} from "@mui/material";
import "./department.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BiLayerPlus } from "react-icons/bi";
//component
import Profile from "../Component/Menu/Profile";
import DepartmentAction from "../Component/Department/DepartmentAction/DepartmentAction";
import CreateDepartment from "../Component/Department/CreateDepartment/CreateDepartment";
import AlertMessage from "../Component/AlertMessage/AlertMessage";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";
import { GET_DEPARTMENT_BY_ORGANIZATION_PAGINATION } from "../Schema/department";
import { useQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";

export default function Department() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  let organizationId = searchParams.get("organization");

  // console.log("organizationId::", organizationId)

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(
    GET_DEPARTMENT_BY_ORGANIZATION_PAGINATION,
    {
      variables: {
        page: page,
        limit: limit,
        keyword: keyword,
        organizationId: organizationId,
      },
      onCompleted: ({ getDepartmentPagination }) => {
        setTableData(getDepartmentPagination?.data);
        setLoading(false);
      },
      onError: () => {
        setLoading(true);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [organizationId]);

  return (
    <div className="department-page">
      <Box className="contanner-header">
        <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
          <Stack direction="column" justifyContent="center">
            <IconMenuResponsive />
          </Stack>

          <Stack direction="column" justifyContent="center">
            <Box className="slash" />
          </Stack>

          <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
            <Typography className="page-title">
              <Link to="/setting" className="link-homepage">
                ការកំណត់
              </Link>{" "}
              /{" "}
              <Link to="/organization" className="link-homepage">
                អង្គភាព
              </Link>{" "}
              / នាយកដ្ឋាន
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }}></Box>
          <Profile />
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row" justifyContent="left">
              <Box className="search-container">
                <TextField
                  onChange={(e) => setKeyword(e.target.value)}
                  className="text-field"
                  fullWidth
                  id="input-with-sx"
                  placeholder="ស្វែងរក"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-add"
                startIcon={<BiLayerPlus className="icon-add" />}
              >
                បន្ថែមថ្មី
              </Button>
              <Drawer
                sx={{
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: 400,
                  },
                }}
                variant="persistent"
                anchor="right"
                open={open}
              >
                <CreateDepartment
                  setRefetch={refetch}
                  organizationId={organizationId}
                  handleClose={handleClose}
                />
              </Drawer>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="header-title" width="3%">
                  ល.រ
                </TableCell>
                <TableCell className="header-title" sx={{ mr: 5 }}>
                  ឈ្មោះនាយកដ្ឋាន
                </TableCell>
                <TableCell className="header-title">ផ្សេងៗ</TableCell>
                <TableCell className="header-title"></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableHead>
                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={5}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </TableCell>
                </TableRow>
                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={5}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </TableCell>
                </TableRow>
                <TableRow className="header-row">
                  <TableCell className="header-title" colSpan={5}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableBody component={Paper} className="body">
                {tableData?.map((row, index) => (
                  <TableRow className="body-row" key={index}>
                    <TableCell
                      className="body-title"
                      width="3%"
                      onClick={() =>
                        navigate(
                          `/office?department=${row?._id}&organization=${organizationId}`
                        )
                      }
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        navigate(
                          `/office?department=${row?._id}&organization=${organizationId}`
                        )
                      }
                      className="body-title"
                    >
                      {row?.name}
                    </TableCell>

                    <TableCell
                      onClick={() =>
                        navigate(
                          `/office?department=${row?._id}&organization=${organizationId}`
                        )
                      }
                      className="body-title"
                    >
                      {row?.remark}
                    </TableCell>

                    <TableCell className="body-title" align="right">
                      <DepartmentAction
                        organizationId={organizationId}
                        editData={row}
                        setRefetch={refetch}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={data?.getUserPagination?.paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>

          <Box width="120px" marginLeft={2} marginRight={2}>
            <FormControl fullWidth size="small">
              <Select value={limit} onChange={(e) => setLimit(e.target.value)}>
                <MenuItem value={10}>10 / page</MenuItem>
                <MenuItem value={20}>20 / page</MenuItem>
                <MenuItem value={50}>50 / page</MenuItem>
                <MenuItem value={100}>100 / page</MenuItem>
                <MenuItem value={200}>200 / page</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}
