import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import { Box, Button, Slider, Typography, Stack, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import "./cropimagefile.scss";
import getCroppedImg from "./Crop";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import axios from "axios";
import moment from "moment";
import GoglobalStoreage from 'goglobalstoragenpm';

export default function CropImageFile({
  photoURL,
  setOpenCrop,
  setPhotoURL,
  setImageFile,
  setUploadHook,
}) {

  let goglobalStoreage = new GoglobalStoreage();

  React.useEffect(() => {
    goglobalStoreage.createClient("63be61f42b181abe9705ee24", "gHrZjzLgj4Ep2a4eBqy2WnsHUot58lfOM249606xibk");
  }, [])

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const newDate = moment(new Date()).format("MMdYYYY");

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    // console.log("croppedArea", croppedArea)
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url);
      setImageFile(file);
      setOpenCrop(false);
      //upload
      uploadImage(file);
    } catch (error) {
      console.log(error);
    }
  };

  //compress image in customer
  const uploadImage = async (file) => {
    if (!file) return;

    let formData = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const compressedFile = await imageCompression(file, options);
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });

    // formData.append("image", newFile);
    goglobalStoreage.upload("sr_officer", "officerimage", newFile, "")
    setUploadHook(`${newName}.png`)

  };

  return (
    <Dialog open={setOpenCrop} fullWidth maxWidth="sm" className="create-container">
      <DialogTitle>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Crop Image
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={() => setOpenCrop(false)}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent
        dividers
        className="dialog-content"
        sx={{ minWidth: { sm: 300 } }}
      >
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={2.6 / 3}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>

      <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
        <Box sx={{ width: "100%", mb: 1 }}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          <Box>
            <Typography>Rotation: {rotation + "°"}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Crop
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
