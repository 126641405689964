import { gql } from "@apollo/client";

export const CREATE_OFFICER_FRAMEWORK = gql`
    mutation CreateContractsOfficer($input: ContractsOfficerInput!) {
        createContractsOfficer(input: $input) {
            message
            status
        }
    }
`;

export const UPDATE_OFFICER_FRAMEWORK = gql`
    mutation UpdateContractsOfficer($updateContractsOfficerId: ID!, $input: ContractsOfficerInput) {
        updateContractsOfficer(id: $updateContractsOfficerId, input: $input) {
            message
            status
        }
    }
`;

export const DELETE_OFFICER_FRAMEWORK = gql`
    mutation DeleteContractsOfficer($deleteContractsOfficerId: ID!) {
        deleteContractsOfficer(id: $deleteContractsOfficerId) {
            message
            status
        }
    }
`;

export const GET_ALL_OFFICER_FRAMEWORK = gql`
    query GetContractsOfficers {
        getContractsOfficers {
            _id
            contractName
            baseSalary
            remark
            createdAt
        }
    }
`;


export const GET_OFFICER_FRAMEWORK_PAGINATION = gql`
    query GetContractsOfficerPagination($page: Int!, $limit: Int!, $keyword: String!, $pagination: Boolean) {
        getContractsOfficerPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination) {
            data {
                _id
                contractName
                baseSalary
                remark
                createdAt
            }
            paginator {
                slNo
                prev
                next
                perPage
                totalPosts
                totalPages
                currentPage
                hasPrevPage
                hasNextPage
                totalDocs
            }       
        }
    }
`
