import React, { useEffect, useState } from "react";
import "./layout.scss";
import { styled } from "@mui/material/styles";
import {
  Box,
  Backdrop,
  Drawer,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import MenuNavbar from "../Component/Menu/MenuNavbar";
import BgImage from "../../src/Assets/Slide_template_bg.png";
import { GET_USER_LOGIN } from "../Schema/user";
import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import logo from "../Assets/logoLogin.svg";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export default function Layout() {
  let location = useLocation();

  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const [openMobile] = useState(false);

  // Resize width
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  //background image
  // const styles = {
  //   paperContainer: {
  //     // backgroundImage: `url(${BgImage})`,
  //     backgroundColor:"#E3F2FD",

  //   },
  // };

  // Sleep Screen ============================================================================
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [iconReload, setIconReload] = React.useState(false);

  const handleCloseBackdrop = () => {
    setIconReload(true);
    setTimeout(() => {
      setOpenBackdrop(false);
      setIconReload(false);
    }, 2000);
    window.location.reload(location.pathname);
  };

  const { data } = useQuery(GET_USER_LOGIN, {
    pollInterval: 10000,
    onError: (error) => {
      let result = error.message.includes("expired");
      if (result) {
        setOpenBackdrop(true);
      }
    },
  });

  // ===============================================================================================

  if (openBackdrop) {
    return (
      <Backdrop
        className="backdrop-style"
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        transitionDuration={{ enter: 500, exit: 1000 }}
        open={openBackdrop}
      >
        <Box className="background-image" />
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ height: "100vh" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="90%"
          >
            <Box display="flex" justifyContent="center" width="100%">
              <img src={logo} alt="logo" width="45%" />
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography className="system-title">
                OFFICER MANAGEMENT SYSTEM
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="contained"
                endIcon={
                  iconReload ? (
                    <CircularProgress sx={{ color: "white" }} size="25px" />
                  ) : (
                    <ReplayIcon />
                  )
                }
                onClick={handleCloseBackdrop}
              >
                Back to Page
              </Button>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ mb: "10px" }}
          >
            <Typography className="power-title">
              Power By: GO GLOBAL IT
            </Typography>
          </Box>
        </Stack>
      </Backdrop>
    );
  }

  return (
    <Box
      className="main"
    
      // style={styles.paperContainer}
    >
      {width > 1400 ? (
        <Box className="inner"   sx={{ display: "flex" }}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <MenuNavbar
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
          </Drawer>
          <Main open={open} className="outlet-container">
            <Outlet />
          </Main>
        </Box>
      ) : (
        <Box className="inner"  sx={{ display: "flex" }}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={openMobile}
          ></Drawer>
          <Main open={openMobile} className="outlet-container">
            <Outlet />
          </Main>
        </Box>
      )}
    </Box>
  );
}
