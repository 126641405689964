import { gql } from "@apollo/client"

export const GET_CARD_DATA = gql`
query GetCardData {
    getCardData {
      nationalOfficer
      provincialOfficials
      districtOfficer
      communeOfficer
    }
  }
`

export const GET_EDUCATION_OFFICE = gql`
  query GetPieChartData($officerType: String) {
    getPieChartData(officerType: $officerType) {
      series
      labels
    }
  }
`