import React from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import "./setting.scss";
import { Link } from "react-router-dom";
//Assets
import DeskIcon from "../Assets/office-desk.png"
import ChairIcon from "../Assets/office-chair.png"
import PersonIcon from "../Assets/programmer.png"
import Police from "../Assets/policeman.png"
import OffceFramword from "../Assets/chief-executive-officer.png"
//component
import Profile from "../Component/Menu/Profile";
import IconMenuResponsive from "../Component/Menu/IconMenuResponsive";

export default function User() {

  return (
    <div className="setting-page">
      <Box className="contanner-header">
        <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
          <Stack direction="column" justifyContent="center">
            <IconMenuResponsive />
          </Stack>

          <Stack direction="column" justifyContent="center">
            <Box className="slash" />
          </Stack>

          <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
            <Typography className="color"> ការកំណត់ </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }}></Box>
          <Profile />
        </Stack>
      </Box>

      {/* ChairIcon
      PersonIcon */}

      <Box className="container">
        <Grid itex container spacing={3}>
          {/* item */}
          <Grid item xs={12} sm={6} lg={3} xl={2.4}>
            <Link to="/organization" style={{ textDecoration: "none" }}>
              <Box className="page-container" sx={{ backgroundColor: "#44C494" }}>
                <Stack direction="row" justifyContent="center">
                  <Stack direction="row" justifyContent="center" className="page-image">
                    <img className="image" src={DeskIcon} />
                  </Stack>
                </Stack>
                <Typography className="page-title">
                  ការកំណត់អង្គភាព
                </Typography>
                <Typography className="page-description">
                  ការកំណត់ប្រព័ន្ធ និងការិយាល័យក្រោមការគ្រប់គ្រងរបស់អង្គភាព។
                </Typography>
              </Box>
            </Link>
          </Grid>

          {/* item */}
          <Grid item xs={12} sm={6} lg={3} xl={2.4}>
            <Link to="/position" style={{ textDecoration: "none" }}>
              <Box className="page-container" sx={{ backgroundColor: "#01B0AE" }}>
                <Stack direction="row" justifyContent="center">
                  <Stack direction="row" justifyContent="center" className="page-image">
                    <img className="image" src={ChairIcon} />
                  </Stack>
                </Stack>
                <Typography className="page-title">
                  កំណត់តំណែងរបស់មន្ត្រី
                </Typography>
                <Typography className="page-description">
                  ការកំណត់ និងរៀបចំតំណែង របស់មន្ត្រីតាមឋានានុក្រម
                </Typography>
              </Box>
            </Link>
          </Grid>

          {/* item */}
          <Grid item xs={12} sm={6} lg={3} xl={2.4}>
            <Link to="/officer-info" style={{ textDecoration: "none" }}>
              <Box className="page-container" sx={{ backgroundColor: "#5188CB" }}>
                <Stack direction="row" justifyContent="center">
                  <Stack direction="row" justifyContent="center" className="page-image">
                    <img className="image" src={Police} />
                  </Stack>
                </Stack>
                <Typography className="page-title">
                  កំណត់ព័ត៌មានមន្រ្តី
                </Typography>
                <Typography className="page-description">
                  ការកំណត់ព័ត៌មានផ្ទាល់ខ្លួន​ និងព័ត៌មានរបស់អង្គភាព។
                </Typography>
              </Box>
            </Link>
          </Grid>

          {/* item */}
          <Grid item xs={12} sm={6} lg={3} xl={2.4}>
            <Link to="/user" style={{ textDecoration: "none" }}>
              <Box className="page-container" sx={{ backgroundColor: "#5362B5" }}>
                <Stack direction="row" justifyContent="center">
                  <Stack direction="row" justifyContent="center" className="page-image">
                    <img className="image" src={PersonIcon} />
                  </Stack>
                </Stack>
                <Typography className="page-title">
                  កំណត់អ្នកប្រើប្រាស់
                </Typography>
                <Typography className="page-description">
                  ការកំណត់សិទ្ធិ និងគ្រប់គ្រងអ្នកប្រើប្រាស់។
                </Typography>
              </Box>
            </Link>
          </Grid>

          {/* item */}
          <Grid item xs={12} sm={6} lg={3} xl={2.4}>
            <Link to="/office-framework" style={{ textDecoration: "none" }}>
              <Box className="page-container" sx={{ backgroundColor: "#33395b" }}>
                <Stack direction="row" justifyContent="center">
                  <Stack direction="row" justifyContent="center" className="page-image">
                    <img className="image" src={OffceFramword} />
                  </Stack>
                </Stack>
                <Typography className="page-title">
                  កំណត់ក្របខ័ណ្ឌមន្ត្រី
                </Typography>
                <Typography className="page-description">
                  ការកំណត់ឋានន្តរស័ក្កិ និងសន្ទស្សន៍បៀវត្ស។
                </Typography>
              </Box>
            </Link>
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}
