import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import "./updateoffice.scss";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import { UPDATE_OFFICE, GET_LAST_OFFICE_RANKING } from "../../../Schema/office";

export default function UpdateOffice({
  organizationId,
  departmentId,
  editData,
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [lastOfficeRanking, setLastOfficeRanking] = useState()

  const { data, refetch } = useQuery(GET_LAST_OFFICE_RANKING, {
    variables: {
      organizationId: organizationId,
    },
    onCompleted: ({ getLastOfficeRanking }) => {
      setLastOfficeRanking(getLastOfficeRanking);
    },
    onError: (error) => {
      setLoading(true);
    },
  });

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onCompleted: ({ updateOffice }) => {
      setLoading(false);
      if (updateOffice?.status === true) {
        setAlert(true, "success", updateOffice?.message)
        setRefetch();
        handleClose();
      } else {
        setAlert(true, "error", updateOffice?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  const UpdateOffice = Yup.object().shape({
    name: Yup.string().required("ទាមទារ!"),
    officeRanking: Yup.string().required("ទាមទារ!"),
    remark: Yup.string(),
  });

  // empty field
  const formik = useFormik({
    initialValues: {
      name: "",
      officeRanking: 1,
      remark: "",
    },
    validationSchema: UpdateOffice,
    onSubmit: (values) => {
      // console.log(values, "create unit");
      setLoading(true);
      updateOffice({
        variables: {
          id: editData?._id,
          name: values?.name,
          officeRanking: values?.officeRanking,
          remark: values?.remark,
          organization: organizationId,
          departemnt: departmentId,
        }
      })

    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;


  useEffect(() => {
    if (editData) {
      setFieldValue("name", editData?.name)
      setFieldValue("officeRanking", editData?.officeRanking === null ? lastOfficeRanking : editData?.officeRanking)
      setFieldValue("remark", editData?.remark)
    }
  }, [editData, lastOfficeRanking])

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="create-organization-officer">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                កែប្រែការិយាល័យ
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2} mt={1}>

              <Grid item xs={12}>
                <Typography className="Sub-title">ឈ្មោះការិយាល័យ</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="ឈ្មោះអង្គភាព"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">លេខចំណាត់ថ្នាក់ការិយាល័យ</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  type="number"
                  inputProps={{ min: 1 }}
                  placeholder="លេខចំណាត់ថ្នាក់ការិយាល័យ"
                  {...getFieldProps("officeRanking")}
                  error={Boolean(touched.officeRanking && errors.officeRanking)}
                  helperText={touched.officeRanking && errors.officeRanking}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                    startIcon={<BiLayerPlus />}
                  >
                    កែប្រែ
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
