import React, { useState, useEffect } from "react";
import { Box, FormControl, Stack, Typography, Select, MenuItem } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
//Srcs
import "./evaluateofficers.scss";
import { GET_EDUCATION_OFFICE } from "../../../Schema/dashboard";
import LoadingPage from "../../Include/LoadingPage";

export default function EducationOfficers() {

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([])
  const [officerType, setOfficerType] = useState("មន្ត្រីទាំងអស់")

  const { data, refetch } = useQuery(GET_EDUCATION_OFFICE, {
    variables: {
      officerType: officerType === "មន្ត្រីទាំងអស់" ? "" : officerType
    },
    onCompleted: ({ getPieChartData }) => {
      setChartData(getPieChartData);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
      setLoading(true);
    },
    pollInterval: 10000,
  });

  const state = {
    series: chartData?.series,
    options: {
      colors: ['#03001C', '#e40115', '#F97B22', '#027abf', '#02c080', '#7629f2', '#0e0593', '#B4A3D8'],
      chart: {
        type: "donut",
      },
      noData: {
        text: 'No Data',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        show: true,
        rotate: 0,
        min: 0,
        style: {
          color: "pink",
          fontSize: '14px',
          fontFamily: "Siemreap"
        },
      },
      labels: chartData?.labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250,
            },
            legend: {
              show: false,
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val, opts) {
          let newVal = val?.toFixed(2) + "%";
          return newVal;
        },
        style: {
          fontSize: "12px",
          fontFamily: "Siemreap",
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            " នាក់" +
            "</span>" +
            "</div>"
          );
        },
      },
    },
  };

  return (
    <>
      <Typography className="employee-overview-header">
        កម្រិតវប្បធម៌មន្ត្រី
      </Typography>
      <Box className="employee-chart">
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography>ជ្រើសរើសប្រភេទមន្រ្តី :</Typography>
          </Stack>
          <FormControl size="small" sx={{ width: "40%" }}>
            <Select
              IconComponent={(props) => (
                <KeyboardArrowDownOutlinedIcon {...props} />
              )}
              value={officerType}
              onChange={(e) => setOfficerType(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="មន្ត្រីទាំងអស់">
                <Typography>មន្ត្រីទាំងអស់</Typography>
              </MenuItem>
              <MenuItem value="មន្រី្តថ្នាក់ជាតិ">
                <Typography>មន្រី្តថ្នាក់ជាតិ</Typography>
              </MenuItem>
              <MenuItem value="មន្រី្តថ្នាក់ខេត្ត">
                <Typography>មន្រី្តថ្នាក់ខេត្ត</Typography>
              </MenuItem>
              <MenuItem value="មន្រី្តថ្នាក់ស្រុក">
                <Typography>មន្រី្តថ្នាក់ស្រុក</Typography>
              </MenuItem>
              <MenuItem value="មន្រី្តថ្នាក់ឃុំ">
                <Typography>មន្រី្តថ្នាក់ឃុំ</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Box sx={{ marginTop: "20px" }}>
          {loading || chartData?.length === 0 ? (
            <LoadingPage />
          ) : (
            <>
              <ReactApexChart
                options={state?.options}
                series={state?.series}
                type="donut"
                height={340}
              />
            </>
          )}
        </Box>
      </Box >
    </>
  );
}
