import React, { useState } from "react";
import {
  Stack,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./evaluationction.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

export default function EvaluationAction({
  editData,
}) {

  let navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);
  const openEl = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEl = () => {
    setAnchorEl(null);
  };

  const handleEvaluation = (id) => {
    navigate(`/evaluation/evaluation-officer?officerId=${id}`)
  }

  const handleEvaluationHistory = (id) => {
    navigate(`/evaluation/evaluation-history?officerId=${id}`)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ color: "#3d3d3d" }} />
      </IconButton>

      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={openEl}
        onClose={handleCloseEl}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => handleEvaluation(editData?._id)}
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="icon-update" />
            <Typography className="text-action">វាយតម្លៃមន្រ្តី</Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => handleEvaluationHistory(editData?._id)}
        >
          <Stack direction="row" spacing={1}>
            <FormatListBulletedOutlinedIcon className="icon-update" />
            <Typography className="text-action">មើលប្រវត្តិវាយតម្លៃ</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
}
