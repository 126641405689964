import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import "./officersbyorganization.scss";
// import { GET_TOTAL_EMPLOYEE_CHART } from "../../Schema/Dashbord";
import { useQuery } from "@apollo/client";
import LoadingPage from "../../Include/LoadingPage";

export default function OfficersByorganization() {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([])

  // const { data, refetch } = useQuery(GET_TOTAL_EMPLOYEE_CHART, {
  //   onCompleted: ({ getTotalEmpCart }) => {
  //     setChartData(getTotalEmpCart);
  //     setLoading(false);
  //   },
  //   onError: (error) => {
  //     console.log(error.message);
  //     setLoading(true);
  //   },
  //   pollInterval: 10000,
  // });

  const state = {
    series: [{
      name: 'ក្របខ័ណ្ឌ',
      data: [44, 55, 41, 64, 22, 43, 21]
    }, {
      name: 'កិច្ចសន្យា',
      data: [53, 32, 33, 52, 13, 44, 32]
    }],

    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: ["សាលាខេត្តសៀមរាប", "មន្ទីរទេសចរណ៍ខេត្ត", "មន្ទីរសង្គមកិច្ច", "មន្ទីរអប់រំ យុវជន និងកីឡា", "មន្ទីរប្រៃសណីយ៍", "មន្ទីរឧស្សាហកម្ម", "មន្ទីរមុខងារសាធារណៈ",],
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    },
  };

  return (
    <>
      <Typography className="employee-overview-header">
        ចំនួនមន្ត្រីតាមអង្គភាព
      </Typography>
      <Box className="employee-chart">
        {loading ? (
          <LoadingPage />
        ) : (
          <ReactApexChart
            options={state?.options}
            series={state?.series}
            type="bar"
            height={390}
          />
        )}
      </Box>
    </>
  );
}
