import { useEffect, useState, useContext } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Layout from "./Layout/Layout";
//Page
import Dashboard from "./Pages/Dashboard";
import User from "./Pages/User";
import OfficeFramework from "./Pages/OfficeFramework";
import Report from "./Pages/Report";
import Login from "./Pages/Login";
import { AuthContext } from "./Context/AuthContext";
import Page404 from "./Pages/Page404";
import Setting from "./Pages/Setting";
import ForgotPassword from "./Pages/ForgotPassword";
import OfficerInfo from "./Pages/OfficerInfo";
import Position from "./Pages/Position";
import Organization from "./Pages/Organization";
import Department from "./Pages/Department";
import Office from "./Pages/Office";
import Evaluation from "./Pages/Evaluation";
import ViewOfficer from "./Component/Officer/ViewOfficer/ViewOfficer"
import EvaluateOfficers from "./Component/Evaluation/EvaluationOfficer/EvaluationOfficer";
import EvaluationHistory from "./Component/Evaluation/EvaluationHistory/EvaluationHistory";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {

  // const { state } = useContext(AuthContext);
  // const [user, setUser] = useState(null);

  // useEffect(() => {
  //   if (state.user) {
  //     setTimeout(() => {
  //       setUser(state.user);
  //     }, 1200);
  //   } else {
  //     setUser(null);
  //   }
  // }, [state]);


  // check Route ==========================================================================
  const { state } = useContext(AuthContext);

  const [user, setUser] = useState(JSON.parse(window.localStorage.getItem("users")));

  useEffect(() => {
    let userStorage = JSON.parse(window.localStorage.getItem("users"));
    if (userStorage) {
      setUser(userStorage);
      return;
    }

    if (state?.user) {
      setTimeout(() => {
        setUser(state?.user?.email);
        window.localStorage.setItem("users", JSON.stringify(state?.user?.email));
      }, 1200);
    } else {
      setUser(null);
    }
  }, [state?.user]);


  // End check Route ==========================================================================

  // console.log(state, "user");

  const Logins = useRoutes([
    { path: "/", element: <Login /> },
    { path: "login", element: <Login /> },
    { path: "forgotpassword", element: <ForgotPassword /> },
    { path: "*", element: <Login /> },
  ]);

  const Pages = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "officer-info", element: <OfficerInfo /> },
        { path: "/officer-info/view-officer", element: <ViewOfficer /> },

        { path: "position", element: <Position /> },
        { path: "organization", element: <Organization /> },
        { path: "setting", element: <Setting /> },
        { path: "department", element: <Department /> },
        { path: "office", element: <Office /> },
        { path: "user", element: <User /> },
        { path: "office-framework", element: <OfficeFramework /> },

        { path: "evaluation", element: <Evaluation /> },
        { path: "/evaluation/evaluation-officer", element: <EvaluateOfficers /> },
        { path: "/evaluation/evaluation-history", element: <EvaluationHistory /> },

        { path: "report", element: <Report /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  if (user !== undefined && user !== null) {
    return Pages;
  } else {
    return Logins;
  }
}
