import React, { useState, useContext } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import "./createdepartment.scss";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import { CREATE_DEPARTMENT } from "../../../Schema/department";

export default function CreateDepartment({
  organizationId,
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [createDepartment] = useMutation(CREATE_DEPARTMENT, {
    onCompleted: ({ createDepartment }) => {
      setLoading(false);
      if (createDepartment?.status === true) {
        setAlert(true, "success", createDepartment?.message)
        setRefetch();
        handleClose();
        resetForm()
      } else {
        setAlert(true, "error", createDepartment?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  const CreateDepartment = Yup.object().shape({
    name: Yup.string().required("ទាមទារ!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      remark: "",
    },

    validationSchema: CreateDepartment,
    onSubmit: (values) => {
      // console.log("values", values);
      setLoading(true);
      createDepartment({
        variables: {
          name: values?.name,
          remark: values?.remark,
          organization: organizationId
        }
      })
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, resetForm } = formik;

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="create-department">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                បង្កើតនាយកដ្ឋាន
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography className="Sub-title">ឈ្មោះនាយកដ្ឋាន</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ឈ្មោះនាយកដ្ឋាន"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                    startIcon={<BiLayerPlus />}
                  >
                    បង្កើតថ្មី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
