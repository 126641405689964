import React, { useState, useContext } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Avatar
} from "@mui/material";
import "./createorganization.scss";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//Srcs
import { AuthContext } from "../../../Context/AuthContext"
import CropImageFile from "../../CropImage/CropImageFile";
import { CREATE_ORGANIZATION } from "../../../Schema/organization";

export default function CreateOrganization({
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    onCompleted: ({ createOrganization }) => {
      setLoading(false);
      if (createOrganization?.status === true) {
        setAlert(true, "success", createOrganization?.message)
        setRefetch();
        handleClose();
        resetForm();
      } else {
        setAlert(true, "error", createOrganization?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message)
      setLoading(false);
    },
  });

  const AddOrganization = Yup.object().shape({
    name: Yup.string().required("ទាមទារ!"),
    remark: Yup.string(),
  });

  // empty field
  const formik = useFormik({
    initialValues: {
      name: "",
      remark: "",
    },

    validationSchema: AddOrganization,

    onSubmit: (values) => {
      // console.log(values, "create unit");
      setLoading(true);
      createOrganization({
        variables: {
          name: values?.name,
          remark: values?.remark,
          imageSrc: profileHook,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="create-organization">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                បង្កើតអង្គភាព
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box width="100%">
                    <Stack direction="row" justifyContent="center">
                      <IconButton component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        <Avatar
                          className="avater-image"
                          src={
                            imageFile ? URL.createObjectURL(imageFile) : null
                          }
                        />
                        <input type="file" hidden />
                      </IconButton>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ mt: 1 }}
                    >
                      <Typography className="Sub-title">
                        ឡូហ្គោរមន្ទីរ
                      </Typography>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setImageFile={setImageFile}
                    photoURL={photoURL}
                    setOpenCrop={setOpenCrop}
                    setPhotoURL={setPhotoURL}
                    setUploadHook={setProfileHook}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">ឈ្មោះអង្គភាព</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="ឈ្មោះអង្គភាព"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                    startIcon={<BiLayerPlus />}
                  >
                    បង្កើតថ្មី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
