import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TableContainer, Table, TableHead, TableBody, TableCell, TableRow,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
//Srcs
import "./evaluationhistory.scss";
import { getKhmerNumber } from '../../../Function/DynamicFunction';
import IconMenuResponsive from "../../Menu/IconMenuResponsive";
import Profile from "../../Menu/Profile";
import { GET_OFFICER_BY_ID } from '../../../Schema/officer'

export default function EvaluationHistory() {
  const [officerData, setOfficerData] = useState([])
  let navigate = useNavigate()

  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let officerId = params.get("officerId");

  const { data, refetch } = useQuery(GET_OFFICER_BY_ID, {
    variables: {
      officerId: officerId,
    },
    onCompleted: ({ getOfficerById }) => {
      // console.log("getOfficerById::", getOfficerById)
      setOfficerData(getOfficerById);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    // console.log(window.innerWidth)
    if (window.innerWidth < 1536) {
      setWidth(window.innerWidth - 100);
    } else {
      setWidth(window.innerWidth - 320);
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleHistoryOfficer = (id) => {
    navigate(`/evaluation/evaluation-history/history-officer?officerId=${id}`)
  }

  return (
    <div className="evaluation-history">
      <Box className="evaluation-officer">
        <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
          <Stack direction="column" justifyContent="center">
            <IconMenuResponsive />
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Box className="slash" />
          </Stack>
          <Stack sx={{ ml: "16px" }} direction="column" justifyContent="center">
            <Typography className="page-title">
              <Link to="/evaluation" className="link-homepage">
                ការវាយតម្លៃ
              </Link>{" "}
              / {officerData?.firstName + " " + officerData?.lastName}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Profile />
        </Stack>

        <Box className="container">
          <TableContainer sx={{ maxWidth: `${width}px` }}>
            <Table className="table" aria-label="simple table">
              <TableHead>
                <TableRow className="header-row">
                  <TableCell className="header-title">ល.រ</TableCell>
                  <TableCell className="header-title">អ្នកវាយតម្លៃ</TableCell>
                  <TableCell className="header-title">មុខ​តំណែង</TableCell>
                  <TableCell className="header-title">មធ្យមពិន្ទុ ការគោរព និងតួនាទីភារកិច្ច​</TableCell>
                  <TableCell className="header-title">មធ្យមពិន្ទុ សីសធម៌</TableCell>
                  <TableCell className="header-title">មធ្យមពិន្ទុ ស្ថានភាពគ្រួសារ</TableCell>
                  <TableCell className="header-title">មធ្យមពិន្ទុ សរុប</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="body">
                {
                  [1, 2, 3, 4]?.map((row, index) => (
                    <TableRow className={(index + 1) % 2 === 1 ? "body-row" : "body-row-active"} key={index} >
                      <TableCell className="body-title">
                        {getKhmerNumber(index + 1)}
                      </TableCell>

                      <TableCell className="body-title">
                        ឈ្មោះ
                      </TableCell>

                      <TableCell className="body-title">
                        មុខ​តំណែង
                      </TableCell>

                      <TableCell className="body-title">
                        23
                      </TableCell>

                      <TableCell className="body-title">
                        12
                      </TableCell>

                      <TableCell className="body-title">
                        22
                      </TableCell>

                      <TableCell className="body-title">
                        23
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  )
}
