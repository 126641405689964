import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

// Firbase for deploy 
const firebaseConfig = {
    apiKey: "AIzaSyDk61oogw99ROgt4tSXmn5THkzAjsPl3Bg",
    authDomain: "sr-officer-management.firebaseapp.com",
    projectId: "sr-officer-management",
    storageBucket: "sr-officer-management.appspot.com",
    messagingSenderId: "302602438430",
    appId: "1:302602438430:web:c6ec6369cd2657489aeb0f"
};

// for development
// const firebaseConfig = {
//     apiKey: "AIzaSyCWwlmR7nr7az6PqT6r0C2hmKfw1MxHUDU",
//     authDomain: "test-inventory-mart.firebaseapp.com",
//     projectId: "test-inventory-mart",
//     storageBucket: "test-inventory-mart.appspot.com",
//     messagingSenderId: "576156604857",
//     appId: "1:576156604857:web:0fe5305244a99a8addbe68"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage();
// Initialize Firebase Authentication and get a reference to the service
export default app;
