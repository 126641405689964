import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import logo from "../../Assets/logoLogin.svg";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import "./menunavbar.scss";

import { useQuery } from "@apollo/client";
import { GET_USER_LOGIN } from "../../Schema/user";

import { RiDashboard2Line, RiBodyScanLine } from "react-icons/ri"
import { IoCopyOutline } from "react-icons/io5"
import { AiOutlineSetting } from "react-icons/ai"
import { BiBookReader } from "react-icons/bi"

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";

import { auth } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open, handleDrawerClose, toggleDrawer }) {
  let location = useLocation();
  const theme = useTheme();

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
        window.localStorage.removeItem("users");
        window.localStorage.removeItem("userLogin");
      })
      .catch((error) => {
        // An error happened.
        // console.log(error);
      });
  };

  const { data, refetch } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      console.log(getUserLogin?.role);
    },
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const [openLogout, setOpenLogout] = React.useState(false);

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  return (
    <>
      <Drawer variant="permanent" open={true} className="drawer-menu">
        <DrawerHeader sx={{ mt: 2, mb: 1 }}>
          <Stack direction="row" justifyContent="center">
            <IconButton onClick={() => navigate("/dashboard")} >
              <img src={logo} alt="logo" className="com-logo" />
            </IconButton>
          </Stack>
        </DrawerHeader>

        <List className="list-menu">
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <RiDashboard2Line className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">ទំព័រដើម</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem
            className={
              location.pathname === "/setting" || location.pathname === "/user" ||
                location?.pathname === "/position" ||
                location?.pathname === "/organization" ||
                location?.pathname === "/department" ||
                location?.pathname === "/office" ||
                location?.pathname === "/officer-info" ||
                location?.pathname === "/office-framework" || location?.pathname === "/officer-info/view-officer"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/setting" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <AiOutlineSetting className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">
                  ការកំណត់
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem
            className={location.pathname === "/evaluation" || location.pathname === "/evaluation/evaluation-officer" || location.pathname === "/evaluation/evaluation-history" ? "list-item-active" : "list-item"} disablePadding sx={{ display: "block" }}>
            <Link to="/evaluation" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <BiBookReader className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">ការវាយតម្លៃ</Typography>
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem className={location.pathname === "/report" ? "list-item-active" : "list-item"} disablePadding sx={{ display: "block" }}>
            <Link to="/report" style={{ textDecoration: "none" }}>
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <IoCopyOutline className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">របាយការណ៍</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Box sx={{ flexGrow: 1 }}></Box>

        <List className="list-menu">
          <ListItem
            className="list-item"
            disablePadding
            onClick={toggleDrawer("left", true)}
            sx={{ display: "block" }}
          >
            <Link
              to="#"
              style={{ textDecoration: "none" }}
              onClick={handleOpenLogout}
            >
              <ListItemButton className="list-item-button" sx={{ px: 2.5 }}>
                <ListItemIcon className="list-item-icon">
                  <LogoutIcon className="icon" />
                </ListItemIcon>
                <Typography className="list-item-text">ចាកចេញ</Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Drawer>

      <div>
        <Dialog open={openLogout} className="log-out-style">
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="right"
              sx={{ marginTop: "-5px" }}
            >
              <IconButton onClick={handleCloseLogout}>
                <DoDisturbOnOutlinedIcon
                  sx={{ color: "red", fontSize: "25px" }}
                />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Stack direction="column" justifyContent="center" height="150px">
                <Stack
                  direction="row"
                  justifyContent="center"
                  width="400px"
                  marginTop="-10px"
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "20px",
                      fontFamily: "Siemreap"
                    }}
                  >
                    តើអ្នកចង់ចាកចេញមែនទេ?
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  width="400px"
                  marginTop="30px"
                >
                  <Button
                    onClick={handleSignOut}
                    autoFocus
                    fullWidth
                    sx={{
                      backgroundColor: "#0B82C4",
                      color: "white",
                      ":hover": {
                        backgroundColor: "#0B82C4",
                      },
                      fontFamily: "Siemreap"
                    }}
                  >
                    ចាកចេញ
                  </Button>
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
