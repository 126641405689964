import React, { useEffect, useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Typography, Box, Grid, TextField, IconButton, Autocomplete, InputAdornment } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { GET_BONUS_TYPE } from "../../Schema/Setting";
import { useQuery } from "@apollo/client";

export default function ListPartyGroup(props) {

    // get Bonus Type =====================================================================
    const [optionBonus, setOptionBonus] = useState([]);

    const items = props.items;
    const listItems = items.map((item) => {
        return (
            <Box key={item.key} sx={{ mb: 2 }}>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className="Sub-title">មុខតំណែងក្នុងបក្ស</Typography>
                        <TextField
                            className="text-field"
                            size="small"
                            fullWidth
                            placeholder="មុខតំណែងក្នុងបក្ស"
                            value={item?.positions}
                            onChange={(e) => props.setUpdatePartyPositions(e.target.value, item?.key)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography className="Sub-title">ឆ្នាំចូលជាសមាជិក</Typography>
                        <TextField
                            className="text-field"
                            size="small"
                            fullWidth
                            placeholder="ឆ្នាំចូលជាសមាជិក"
                            value={item?.joinDate}
                            onChange={(e) => props.setUpdateJoinDate(e.target.value, item?.key)}
                        />
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <IconButton
                            sx={{ cursor: "pointer", marginTop: "20px" }}
                            onClick={() => {
                                props.deletePartyGroup(item.key);
                            }}
                        >
                            <DeleteRoundedIcon sx={{ color: "red" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        );
    });
    return listItems;
}
