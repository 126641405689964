import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Stack,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  FormHelperText
} from "@mui/material";
import "./createposition.scss";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { BiLayerPlus } from "react-icons/bi";
//srcs
import { AuthContext } from "../../../Context/AuthContext"
import { SelectOrganization, SelectDepartment, SelectOffice } from "../../../Function/DynamicSelect";
import { CREATE_POSITION, GET_LAST_POSITIONS_RANKING } from "../../../Schema/position";

export default function CreatePosition({
  setRefetch,
  handleClose,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [organizationVal, setOrganizationVal] = useState({ id: "", title: "" });
  const [departmentVal, setDepartmentVal] = useState({ id: "", title: "" })
  const [officeVal, setOfficeVal] = useState({ id: "", title: "" });
  const [lastPositionsRanking, setLastPositionsRanking] = useState()

  const { data, refetch } = useQuery(GET_LAST_POSITIONS_RANKING, {
    onCompleted: ({ getLastPositionsRanking }) => {
      setLastPositionsRanking(getLastPositionsRanking);
    },
    onError: () => {
      setLoading(true);
    },
  });

  const [createPosition] = useMutation(CREATE_POSITION, {
    onCompleted: ({ createPosition }) => {
      setLoading(false);
      if (createPosition?.status === true) {
        setAlert(true, "success", createPosition?.message)
        setRefetch();
        handleClose();
        resetForm();
        setOrganizationVal({ id: "", title: "" })
        setDepartmentVal({ id: "", title: "" })
        setOfficeVal({ id: "", title: "" })
      } else {
        setAlert(true, "success", createPosition?.message)
      }
    },
    onError: (error) => {
      setAlert(true, "success", error?.message)
      setLoading(false);
    },
  });

  const AddPosition = Yup.object().shape({
    name: Yup.string().required("ទាមទារ!"),
    positionsRanking: Yup.string().required("ទាមទារ!"),
    // organization: Yup.string().required("ទាមទារ!"),
    // department: Yup.string(),
    // office: Yup.string(),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      positionsRanking: 1,
      // organization: "",
      // department: "",
      // office: "",
      remark: "",
    },

    validationSchema: AddPosition,

    onSubmit: (values) => {
      // console.log("values::", values)
      const createValue = {
        name: values?.name,
        positionsRanking: values?.positionsRanking,
        // organization: values?.organization,
        // department: values?.department ? values?.department : null,
        // office: values?.office ? values?.office : null,
        remark: values?.remark,
      }

      setLoading(true);
      createPosition({
        variables: createValue,
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    setFieldValue("positionsRanking", lastPositionsRanking)
  }, [lastPositionsRanking])

  useEffect(() => {
    if (organizationVal?.id !== undefined) {
      setFieldValue("organization", organizationVal?.id)
    } else {
      setFieldValue("organization", "")
    }
  }, [organizationVal?.id])

  useEffect(() => {
    if (departmentVal?.id !== undefined) {
      setFieldValue("department", departmentVal?.id)
    } else {
      setFieldValue("department", "")
    }
  }, [departmentVal?.id])

  useEffect(() => {
    if (officeVal?.id !== undefined) {
      setFieldValue("office", officeVal?.id)
    } else {
      setFieldValue("office", "")
    }
  }, [officeVal?.id])

  return (
    <Box sx={{ padding: "20px" }}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box spacing={5} className="create-position-officer">
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" className="title">
                បង្កើតតំណែងមន្ត្រី
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>

            <Grid container spacing={2} mt={1}>

              {/* <Grid item xs={12}>
                <Typography className="Sub-title">ជ្រើសរើសអង្គភាព</Typography>
                <SelectOrganization
                  selectedVal={organizationVal}
                  setSelectedVal={setOrganizationVal}
                  setDepartmentVal={setDepartmentVal}
                  setOfficeVal={setOfficeVal}
                />
                {!!errors.organization && touched.organization && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.organization}
                  </FormHelperText>
                )}
              </Grid> */}

              {/* <Grid item xs={12}>
                <Typography className="Sub-title">ជ្រើសរើសនាយកដ្ឋាន</Typography>
                <SelectDepartment
                  selectedVal={departmentVal}
                  setSelectedVal={setDepartmentVal}
                  organizationId={organizationVal?.id}
                />
              </Grid> */}

              {/* <Grid item xs={12}>
                <Typography className="Sub-title">ជ្រើសរើសការិយាល័យ</Typography>
                <SelectOffice
                  selectedVal={officeVal}
                  setSelectedVal={setOfficeVal}
                  organizationId={organizationVal?.id}
                  departmentId={departmentVal?.id}
                />
              </Grid> */}

              <Grid item xs={12}>
                <Typography className="Sub-title">មុខតំណែង</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  placeholder="មុខតំណែង"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">លេខចំណាត់ថ្នាក់តំណែង</Typography>
                <TextField
                  className="text-field"
                  size="small"
                  fullWidth
                  type="number"
                  inputProps={{ min: 1 }}
                  placeholder="លេខចំណាត់ថ្នាក់តំណែង"
                  {...getFieldProps("positionsRanking")}
                  error={Boolean(touched.positionsRanking && errors.positionsRanking)}
                  helperText={touched.positionsRanking && errors.positionsRanking}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="Sub-title">ផ្សេងៗ</Typography>
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" variant="text" fullWidth>
                    រង់ចាំ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    variant="text"
                    fullWidth
                    size="small"
                    type="submit"
                    startIcon={<BiLayerPlus />}
                  >
                    បង្កើតថ្មី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
