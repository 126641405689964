export function getKhmerNumber(number) {
    let numArr = number?.toString()?.split("");

    let numberKh = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
    let newArr = [];

    for (let i = 0; i < numArr?.length; i++) {
        if (isNaN(parseFloat(numArr[i]))) {
            newArr.push(numArr[i]);
            continue;
        }
        newArr.push(numberKh[numArr[i]]);
    }
    return newArr?.join("");
}

// export function getRomanNumber(number) {
//     let numArr = number?.toString()?.split("");

//     let romanNum = ["0", "I", "II", "III", "IV", "V", "VI", "VII", "VIII"];
//     let newArr = [];

//     for (let i = 0; i < numArr?.length; i++) {
//         if (isNaN(parseFloat(numArr[i]))) {
//             newArr.push(numArr[i]);
//             continue;
//         }
//         newArr.push(romanNum[numArr[i]]);
//     }
//     return newArr?.join("");
// }

export function getRomanNumber(day) {
    let romanNum;
    switch (day) {
        case "1":
            romanNum = "I";
            break;
        case "2":
            romanNum = "II";
            break;
        case "3":
            romanNum = "III";
            break;
        case "4":
            romanNum = "IV";
            break;
        case "5":
            romanNum = "V";
            break;
        case "6":
            romanNum = "VI";
            break;
        case "7":
            romanNum = "VII";
        case "8":
            romanNum = "VIII";
    }
    return romanNum;
}

export function getFormattedPhoneNum(input) {
    let output = "";
    input.replace(
        /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
        function (match, g1, g2, g3) {
            if (g1.length) {
                output += g1;
                if (g1.length == 3) {
                    output += "";
                    if (g2.length) {
                        output += " " + g2;
                        if (g2.length == 3) {
                            output += " ";
                            if (g3.length) {
                                output += g3;
                            }
                        }
                    }
                }
            }
        }
    );
    return output;
}